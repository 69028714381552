import React from 'react';

import { observer } from 'mobx-react';
import store, { VerificationStatus } from 'store/mainStore';
import {
  WarningOutlined, ClockCircleOutlined, IssuesCloseOutlined, SafetyOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';

type Props = {
    status: VerificationStatus;
}

const VerificationTag = ({ status }: Props) => {
  const {
    langPack: {
      verification_status,
      pending,
      in_progress,
      verified,
      not_verified,
    },
  } = store;

  const getColor = (key: VerificationStatus) => {
    switch (key) {
      case 'PENDING':
        return (
          <Tag color="warning" icon={<IssuesCloseOutlined />}>
            {verification_status}
            {' '}
            {pending}
          </Tag>
        );
      case 'INPROGRESS':
        return (
          <Tag color="processing" icon={<ClockCircleOutlined />}>
            {verification_status}
            {' '}
            {in_progress}
          </Tag>
        );
      case 'VERIFIED':
        return (
          <Tag color="success" icon={<SafetyOutlined />}>
            {verification_status}
            {' '}
            {verified}
          </Tag>
        );
      default:
        return (
          <Tag color="warning" icon={<WarningOutlined />}>
            {verification_status}
            {' '}
            {not_verified}
          </Tag>
        );
    }
  };

  return (<>{getColor(status)}</>);
};

export default observer(VerificationTag);
