/* eslint-disable no-console */
/* eslint-disable max-len */

import { useEffect } from 'react';

import store from 'store/mainStore';
import { observer } from 'mobx-react';
import { fixAddresLength } from 'utils';
import { Alert, Modal, notification } from 'antd';
import { LoadingOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ButtonAnt from 'components/ButtonAnt';
import useRequest from 'hooks/useRequest';
import {
  infinitySize, placeOrderCFG, updateOrderCFG,
} from 'configs';
import { getConnectData, createWEB3 } from 'utils/metamaks_utils';

const keyMessage = 'send_redeem';

type Props = {
    isOpen: boolean;
    toogleModal: any;
    isMobile: boolean;
    orderData: any;
    redeem?: boolean;
    clearForm?: Function;
    ntwk?: any;
}

const OrderModal = ({
  isOpen, toogleModal, orderData, redeem, clearForm, ntwk,
}: Props) => {
  const {
    langPack: {
      order_order,
      order_member,
      order_amount,
      order_network,
      order_payment_method,
      order_bank_account,
      order_created,
      order_state,
      order_user_wallet_address,
      order_btn_place_order,
      user_btn_cancel,
      order_review_redeem,
      order_payment_service,
      order_wire_transfer,
      order_review_acquire,
      user_bank_name,
      user_bank_bic,
      user_bank_account_number,
      attention_que,
      order_lh_bank_info,
      attention,
    },
    lang,
    metamask,
    setMetamask,
    companyAccounts,
  } = store;

  const { fetch, isSuccess, isLoading, dropState } = useRequest(placeOrderCFG);

  const { fetch: updateFetch } = useRequest(updateOrderCFG);

  const pm = (pmet: string) => (pmet === 'PAYMENT_SERVICE' ? order_payment_service : order_wire_transfer);

  const placeOrder = () => {
    // TODO NOT FOR ACQUIRE PAYMENT SERVICE
    fetch({
      ...(orderData.paymentData.paymentMethodEnum === 'PAYMENT_SERVICE' ? { providerId: orderData.paymentData.paymentProviderId } : { accountId: orderData.paymentData.userAccount.id }),
      orderNumber: orderData.orderNumber,
      paymentMethodEnum: orderData.paymentData.paymentMethodEnum,
      orderType: redeem ? 'REDEEM' : 'ACQUIRING',
    });
  };

  useEffect(() => {
    if (isSuccess && isOpen) {
      if (!redeem && orderData.paymentData.paymentMethodEnum === 'PAYMENT_SERVICE') {
        window.location.href = `https://pay.freekassa.ru/?m=21372&oa=${orderData.amount}&o=${orderData.orderNumber}&s=${orderData.paymentData.signKey}&lang=${lang.toLowerCase()}&currency=RUB`;
      }
      // TODO SET MERCHANT ID like m
      dropState();
      toogleModal();
      clearForm && isOpen && clearForm();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, toogleModal, isOpen, clearForm, dropState]);

  useEffect(() => {
    if (!isSuccess || !orderData || !redeem) return;

    const fetchData = async () => {
      notification.open({
        duration: 0,
        key: keyMessage,
        message: 'Ордер создан',
        icon: <LoadingOutlined style={{ color: '#00CC00' }} />,
        description: 'Ждем подписание транзакции в METAMASK...',
      });

      const connectObj = getConnectData(ntwk);
      const contract = createWEB3(connectObj, true);
      try {
        const res = await contract.methods.redeem(BigInt(orderData.amount * 1000000), orderData.address, 'withdraw', {}, JSON.stringify({ trnNumber: orderData.trnNumber })).send({ from: metamask.address });
        setMetamask({
          ...metamask,
          balance: metamask.balance - orderData.amount,
          allowance: metamask.allowance !== infinitySize ? metamask.allowance - orderData.amount : metamask.allowance,
        });
        return res;
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.log('err', err);

        const rejectBody = {
          orderNumber: orderData.orderNumber,
          responseStatus: 'REJECTED',
          responseCode: err.code,
          orderType: redeem ? 'REDEEM' : 'ACQUIRING',
          paymentMethodEnum: orderData.paymentData.paymentMethodEnum,
        };
        if (err.code === 4001 || err.code === -32603) {
          notification.open({
            duration: 10,
            icon: <CloseCircleOutlined style={{ color: '#ff0000' }} />,
            key: keyMessage,
            message: 'Транзакция отлонена',
            description: 'Вы отклонили подписание транзакции или что то пошло не так, попробуйте снова',
          });
          updateFetch(rejectBody);
          console.log('rejectBody', rejectBody);
        }
        console.log('ERROR REJECT', err);
        // code: 4001
        // message: "MetaMask Tx Signature: User denied transaction signature."
        throw new Error(err);
      }
    };
    fetchData()
      .then(() => notification.open({
        duration: 10,
        key: keyMessage,
        message: 'Готово',
        icon: <CheckCircleOutlined style={{ color: '#00CC00' }} />,
        description: 'Транзакция отправлена, ожидаем подтверждения сети. Статус обмена можете наблюдать на странице активности',
      }))
      .catch(err => console.log('err', err));
  }, [isSuccess, metamask, orderData, redeem, setMetamask, updateFetch, ntwk]);

  if (!Object.keys(orderData).length) return <></>;

  return (
    <Modal
      visible={isOpen}
      destroyOnClose
      title={redeem ? order_review_redeem : order_review_acquire}
      footer={[
        <ButtonAnt loading={isLoading} key="a" type="primary" onClick={placeOrder}>{order_btn_place_order}</ButtonAnt>,
        <ButtonAnt disabled={isLoading} key="b" onClick={toogleModal}>{user_btn_cancel}</ButtonAnt>,
      ]}
      zIndex={900}
      style={{ top: 20 }}
      onCancel={toogleModal}
    >
      <div>
        <div className="accBox_line">
          <span>{order_order}</span>
          <span>{orderData.orderNumber}</span>
        </div>
        <div className="accBox_line">
          <span>{order_member}</span>
          <span>{orderData.userNumber}</span>
        </div>
        <div className="accBox_line">
          <span>{order_amount}</span>
          <span>{`${orderData.amount} RUBL`}</span>
        </div>
        <div className="accBox_line">
          <span>{order_network}</span>
          <span>{orderData.networkName}</span>
        </div>
        <div className="accBox_line">
          <span>{order_user_wallet_address}</span>
          <span>{fixAddresLength(orderData.address)}</span>
        </div>
        <div className="accBox_line">
          <span>{order_payment_method}</span>
          <span>{pm(orderData.paymentData?.paymentMethodEnum)}</span>
        </div>
        <div className="accBox_line">
          <span>{orderData.paymentData.paymentMethodEnum !== 'PAYMENT_SERVICE' ? order_bank_account : order_payment_service}</span>
          <span>
            {
            orderData.paymentData.paymentMethodEnum === 'PAYMENT_SERVICE'
              ? orderData.paymentData.providerName
              : orderData.paymentData.userAccount.accountNick
          }

          </span>
        </div>
        <div className="accBox_line">
          <span>{order_created}</span>
          <span>{orderData.createdDate}</span>
        </div>
        <div className="accBox_line">
          <span>{order_state}</span>
          <span>{orderData.stateEnum}</span>
        </div>
        {
          orderData.paymentData.paymentMethodEnum !== 'PAYMENT_SERVICE' && !redeem
            ? (
              <>
                <div className="bankAccount">
                  <div className="bankAccount_header">
                    {order_lh_bank_info}
                  </div>
                  <div className="accBox_line">
                    <span>
                      {user_bank_name}
                    </span>
                    <span>
                      {companyAccounts?.bankName || '---'}
                    </span>
                  </div>
                  <div className="accBox_line">
                    <span>
                      {user_bank_bic}
                    </span>
                    <span>
                      {companyAccounts?.bankBIC || '---'}
                    </span>
                  </div>
                  <div className="accBox_line">
                    <span>
                      {user_bank_account_number}
                    </span>
                    <span>
                      {companyAccounts?.bankAccount || '---'}
                    </span>
                  </div>
                </div>
                <div className="mt20" />
                <Alert
                  message={attention}
                  description={attention_que}
                  type="info"
                  showIcon
                />
              </>
            ) : null
        }
      </div>
    </Modal>
  );
};

export default observer(OrderModal);
