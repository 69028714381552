// eslint-disable-next-line import/prefer-default-export
export const LOCALE = {
  lang: {
    locale: 'ru_RU',
    placeholder: 'Укажите дату',
    rangePlaceholder: ['Начало', 'Окончание'] as [string, string],
    today: 'Сегодня',
    now: 'Сейчас',
    backToToday: 'На сегодня',
    ok: 'ОК',
    clear: 'Сбросить',
    month: 'Месяц',
    year: 'Год',
    timeSelect: 'Укажите время',
    dateSelect: 'Укажите дату',
    monthSelect: 'Укажите месяц',
    yearSelect: 'Укажите год',
    decadeSelect: 'Укажите декаду',
    yearFormat: 'YYYY',
    dateFormat: 'D/M/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D/M/YYYY HH:mm:ss',
    monthFormat: 'MMMM',
    monthBeforeYear: true,
    previousMonth: 'Предыдущий месяц (PageUp)',
    nextMonth: 'Следующий месяц (PageDown)',
    previousYear: 'Предыдущий год (Control + left)',
    nextYear: 'Следующий год (Control + right)',
    previousDecade: 'Предыдущая декада',
    nextDecade: 'Следующая декада',
    previousCentury: 'Предыдущий век',
    nextCentury: 'Следующий век',
  },
  timePickerLocale: {
    placeholder: 'Укажите время',
  },
  dateFormat: 'YYYY-MM-DD',
  dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  weekFormat: 'YYYY-wo',
  monthFormat: 'YYYY-MM',
};
