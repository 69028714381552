import { useEffect, useState } from 'react';

import HelloIndentification from 'pages/IdentyVerification/HelloIndentification';
import { Modal } from 'antd';
import ButtonAnt from 'components/ButtonAnt';
import GetDocuments from 'pages/IdentyVerification/GetDocuments';
import GetSelfie from 'pages/IdentyVerification/GetSelfie';
import store from 'store/mainStore';
import SendVerification from 'pages/IdentyVerification/SendVerification';
import useRequest from 'hooks/useRequest';
import { meData, verification } from 'configs';
import { useMount } from 'hooks';
import { toJS } from 'mobx';
// import GetDocuments from './GetDocuments';
type Props = {
    isOpen: boolean;
    toogleModal: any;
}
const VerificationModal = ({ isOpen, toogleModal }: Props) => {
  const {
    langPack: {
      identity_verification,
      identity_verification_btn_continue,
      back,
      confirm,
    },
    setPayload,
    payload,
    setUserData,
  } = store;
  const {
    fetch, isSuccess, isError,
  } = useRequest(verification);

  const {
    fetch: getAuthData, requestResult: userData, isSuccess: isGotData,
  } = useRequest(meData);

  const stages = ['hello', 'getDocuments', 'getSelfie', 'complited'];

  const [stage, setStage] = useState<string>('hello');

  const getNextStage = (stage_now: string) => {
    const index = stages.indexOf(stage_now);
    return stages[index + 1];
  };

  const getPrevStage = (stage_now: string) => {
    const index = stages.indexOf(stage_now);
    return stages[index - 1];
  };
  const stageHandleBack = () => {
    setStage(getPrevStage(stage));
  };

  const stageHandle = () => {
    if (stage === 'complited') {
      const data = toJS(payload);
      fetch(data);
      return;
    }
    setStage(getNextStage(stage));
  };

  const getContent = (state: string) => {
    switch (state) {
      case 'hello':
        return <HelloIndentification />;
      case 'getDocuments':
        return <GetDocuments />;
      case 'getSelfie':
        return <GetSelfie />;
      case 'complited':
        return <SendVerification isSuccess={isSuccess} isError={isError} />;
      default:
        return <></>;
    }
  };
  useMount(() => setPayload({
    isPassportImageUploaded: false,
    isPassportRegImageUploaded: false,
    isSelfieImageUploaded: false,
  }));

  useEffect(() => {
    isSuccess && getAuthData();
  }, [isSuccess, getAuthData]);

  useEffect(() => {
    (userData && isGotData) && setUserData(userData);
  }, [userData, isGotData, setUserData]);

  return (
    <Modal
      visible={isOpen}
      title={identity_verification}
      zIndex={950}
      destroyOnClose
      style={{ top: '10px', minHeight: '80vh', maxWidth: '1128px' }}
      width="90%"
      onCancel={toogleModal}
      footer={[
        <ButtonAnt key="back" onClick={() => stageHandleBack()} disabled={!getPrevStage(stage)}>
          {back}
        </ButtonAnt>,
        <ButtonAnt key="next" onClick={() => (isSuccess ? toogleModal() : stageHandle())} type="primary">
          {stage === 'complited' ? confirm : isSuccess ? 'Close' : identity_verification_btn_continue}
        </ButtonAnt>,
      ]}
      bodyStyle={{ minHeight: '80vh', width: '80vw', maxWidth: '1128px' }}
    >
      {getContent(stage)}
    </Modal>
  );
};
export default VerificationModal;
