import { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import store from 'store/mainStore';
import useRequest from 'hooks/useRequest';
import { getOrders } from 'configs';
import { useMount } from 'hooks';
import {
  Form, Table, message, Tag,
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { fixAddresLength } from 'utils';
import { TableFooter } from 'components/Table/TableFooter';
import { SelectItem } from 'components/forms/InputItem/SelectItem';
import ButtonAnt from 'components/ButtonAnt';
import InputItem from 'components/forms/InputItem/InputItem';

function ucFirst(str: string) {
  if (!str) return str;

  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

const copyClipBoard = (str: string) => {
  navigator.clipboard.writeText(str);
  message.success({
    content: 'Скопировано в буфер обмена',
    duration: 3,
    icon: <CopyOutlined style={{ color: '#2DAFE7' }} />,
  });
};

export const getStatusOrder = (status: string) => {
  switch (status) {
    case 'DONE':
      return (
        <Tag color="success">
          {ucFirst(status)}
        </Tag>
      );
    case 'INPROGRESS':
    case 'PENDING':
      return (
        <Tag color="warning">
          {ucFirst(status)}
        </Tag>
      );
    case 'REJECTED':
    case 'EXPIRED':
      return (
        <Tag color="error">
          {ucFirst(status)}
        </Tag>
      );
    default:
      break;
  }
};

const Activity = () => {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [filters, setFilter] = useState<any>({});

  const [form] = Form.useForm();
  const {
    langPack: {
      order_redeem,
      order_acquire,
      last,
      days,
      order_network,
      order_order,
      order_type,
      order_txid,
      order_payment_method,
      order_user_wallet_address,
      order_amount,
      order_time,
      order_state,
      order_order_number,
      apply,
      user_btn_cancel,
      due_date,
    },
    networks,
    // paymentServices,
  } = store;

  const { fetch, isLoading, requestResult } = useRequest(getOrders);

  useMount(() => fetch({}, { perPage, page: 0 }));

  useEffect(() => {
    fetch({}, {
      perPage,
      page: page - 1,
      ...(Object.keys(filters).length && { ...filters }),
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, fetch]);

  useEffect(() => {
    if (page === 1) {
      fetch({}, { perPage, page: 0, ...(Object.keys(filters).length && { ...filters }) });
    }
    setPage(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage]);

  const setFilters = (values: any) => {
    const cleanList: any = {};
    for (const key in values) {
      if (values[key] && values[key] !== 'ALL') {
        cleanList[key] = values[key];
      }
    }
    setFilter(cleanList);
  };

  useEffect(() => {
    if (Object.keys(filters).length) {
      fetch({}, { perPage, page: 0, ...filters });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const optionsNetworks = networks.map((el: any) => ({ label: el.networkName, value: el.id, ...el }));

  const typeOptions = [
    {
      label: order_redeem,
      value: 'REDEEM',
    },
    {
      label: order_acquire,
      value: 'ACQUIRING',
    },
  ];

  const daysOptions = [
    {
      label: `${last} 7 ${days}`,
      value: 7,
    },
    {
      label: `${last} 30 ${days}`,
      value: 30,
    },
    {
      label: `${last} 90 ${days}`,
      value: 90,
    },
  ];

  const stateOptions = [
    {
      label: 'DONE',
      value: 'DONE',
    },
    {
      label: 'In progress',
      value: 'INPROGRESS',
    },
    {
      label: 'Expired',
      value: 'EXPIRED',
    },
    {
      label: 'PENDING',
      value: 'PENDING',
    },
    {
      label: 'REJECTED',
      value: 'REJECTED',
    },
  ];

  const COLUMN = [
    {
      title: order_order,
      dataIndex: 'orderNumber',
      width: 100,
      minWidth: 100,
    },
    {
      title: order_time,
      dataIndex: 'dateTime',
      width: 170,
      minWidth: 170,
    },
    {
      title: order_type,
      dataIndex: 'orderType',
      width: 100,
      minWidth: 100,
      render: (row: any) => <>{row === 'REDEEM' ? order_redeem : order_acquire}</>,
    },
    {
      title: order_amount,
      dataIndex: 'amount',
      width: 130,
      minWidth: 130,
      // TODO ADD VALUE
      render: (row: any) => (row || 0),
    },
    {
      title: order_network,
      dataIndex: 'networkName',
      width: 100,
      minWidth: 100,
      render: (row: any) => row?.[0] || 'unknow',
    },
    {
      title: order_user_wallet_address,
      dataIndex: 'address',
      render: (row: any) => (row?.[0] ? (
        <div style={{ display: 'flex', justifyContent: 'space-between', gridGap: '10px', alignItems: 'center' }}>
          <a href={`https://bscscan.com/address/${row}`} target="_blank" rel="noreferrer">{fixAddresLength(row?.[0])}</a>
          <CopyOutlined onClick={() => copyClipBoard(row)} style={{ color: '#1890ff' }} />
        </div>
      ) : '---'),
    },
    {
      title: order_txid,
      dataIndex: 'txId',
      render: (row: any) => (row?.[0] ? (
        <div style={{ display: 'flex', justifyContent: 'space-between', gridGap: '10px', alignItems: 'center' }}>
          <a href={`https://bscscan.com/tx/${row}`} target="_blank" rel="noreferrer">{fixAddresLength(row?.[0])}</a>
          <CopyOutlined onClick={() => copyClipBoard(row)} style={{ color: '#1890ff' }} />
        </div>
      ) : '---'),
    },
    {
      title: due_date,
      dataIndex: 'expirationDate',
      render: (row: any) => row || '---',
    },
    {
      title: order_payment_method,
      dataIndex: 'paymentMethodEnum',
    },
    {
      title: order_state,
      dataIndex: 'state',
      render: (row: any) => getStatusOrder(row),
    },
  ];

  return (
    <div className="inpage centred" style={{ display: 'flex', minWidth: '1128px' }}>
      <Form
        className="filters"
        form={form}
        name="basic"
        onFinish={setFilters}
        id="search"
        autoComplete="off"
      >
        <InputItem
          label={order_order}
          name="keyword"
          required={false}
          placeholder={order_order_number}
          uneditable={false}
          maxLength={250}
        />
        <SelectItem
          label={order_type}
          options={typeOptions}
          name="type"
          placeholder={order_type}
        />
        <SelectItem
          label={order_state}
          options={stateOptions.map((el: any) => ({ ...el, label: ucFirst(el.label) }))}
          name="state"
          placeholder={order_state}
        />
        <SelectItem
          label={ucFirst(days)}
          options={daysOptions}
          name="days"
          placeholder={ucFirst(days)}
        />
        <SelectItem
          label={order_network}
          options={optionsNetworks.map((el: any) => ({ ...el, label: ucFirst(el.label) }))}
          name="network"
          placeholder={order_network}
        />
        <div className="filters_butt">
          <ButtonAnt
            htmlType="submit"
            type="primary"
            form="search"
          >
            {apply}
          </ButtonAnt>
          <ButtonAnt
            onClick={() => {
              form.resetFields();
              fetch({}, { perPage, page: 0 });
              setFilters({});
            }}
          >
            {user_btn_cancel}
          </ButtonAnt>
        </div>
      </Form>
      <Table columns={COLUMN} dataSource={requestResult?.elements || []} style={{ minWidth: '1128px' }} size="small" loading={isLoading} pagination={false} />
      {requestResult && <TableFooter perPage={perPage} perPageChange={per => setPerPage(per)} currentPage={page} total={requestResult?.totalElement || 0} paginationChange={pag => setPage(pag)} />}
    </div>
  );
};

export default observer(Activity);
