import { FC } from 'react';

import { Input, InputProps } from 'antd';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';

type MaskedInputProps = InputProps & Omit<InputMaskProps, 'prefix'>

const MaskedInput: FC<MaskedInputProps> = ({ prefix, disabled, ...rest }) => (
  <InputMask disabled={disabled} {...rest}>
    {(inputProps: Record<string, any>) => (
      <Input prefix={prefix} disabled={disabled} {...inputProps} />
    )}
  </InputMask>
);
export default MaskedInput;
