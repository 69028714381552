import { useState } from 'react';

import store from 'store/mainStore';
import { observer } from 'mobx-react';
import Button from 'components/Button';
import WebcamModal from 'components/modals/WebcamModal';
import { Upload, UploadProps } from 'antd';

const GetDocuments = () => {
  const { langPack, payload, deletePayloadByKey, setPayloadByKey } = store;
  const [open, setOpen] = useState<boolean>(false);
  const [openSec, setOpenSec] = useState<boolean>(false);

  const toogleModal = () => setOpen(!open);
  const toogleModalSec = () => setOpenSec(!openSec);

  const {
    identity_verification_upload_pass_info,
    identity_verification_inf_page_pass,
    identity_verification_residence_reg_page_pass,
    inf_page_pass_take_photo,
    upload_photo,
    or,
  } = langPack;

  const props: UploadProps = {
    name: 'passportImageBase64',
    accept: 'image/gif, image/jpeg, image/png',
    onChange(info: any) {
      const reader = new FileReader();
      reader.readAsDataURL(info.file);
      reader.onload = () => {
        setPayloadByKey('passportImageBase64', reader.result);
        setPayloadByKey('isPassportImageUploaded', true);
      };
    },
  };

  const propsSec: UploadProps = {
    name: 'passportRegImageBase64',
    accept: 'image/gif, image/jpeg, image/png',
    onChange(info: any) {
      const reader = new FileReader();
      reader.readAsDataURL(info.file);
      reader.onload = () => {
        setPayloadByKey('passportRegImageBase64', reader.result);
        setPayloadByKey('isPassportRegImageUploaded', true);
      };
    },
  };

  const dropImg = (key: string[]) => {
    deletePayloadByKey(key[0]);
    deletePayloadByKey(key[1]);
  };

  return (
    <div className="container centred">
      <WebcamModal isOpen={open} toogleModal={toogleModal} tag="passportImageBase64" />
      <WebcamModal isOpen={openSec} toogleModal={toogleModalSec} tag="passportRegImageBase64" />
      <div className="mt20" />
      <div className="verification_container">
        <div className="verification_unitContainer">
          <p>{identity_verification_inf_page_pass}</p>
          <div className="verification_unit">
            {payload.passportImageBase64 && <img src={payload.passportImageBase64} alt="passportImageBase64" />}
            { !payload.passportImageBase64 ? (
              <div className="verification_unit_button">
                <Button title={inf_page_pass_take_photo} action={toogleModal} />
                <span>{or}</span>
                <Upload {...props} beforeUpload={() => false} showUploadList={false}>
                  <Button title={upload_photo} />
                </Upload>

              </div>
            )
              : <div className="verification_unit_delButton" onClick={() => dropImg(['passportImageBase64', 'isPassportImageUploaded'])}>x</div>}
          </div>
        </div>
        <div className="verification_unitContainer">
          <p>{identity_verification_residence_reg_page_pass}</p>
          <div className="verification_unit">
            {payload.passportRegImageBase64 && <img src={payload.passportRegImageBase64} alt="passportRegImageBase64" />}
            { !payload.passportRegImageBase64 ? (
              <div className="verification_unit_button">
                <Button title={inf_page_pass_take_photo} action={toogleModalSec} />
                <span>{or}</span>
                <Upload {...propsSec} beforeUpload={() => false} showUploadList={false}>
                  <Button title={upload_photo} />
                </Upload>
              </div>
            )
              : <div className="verification_unit_delButton" onClick={() => dropImg(['passportRegImageBase64', 'isPassportRegImageUploaded'])}>x</div>}
          </div>
        </div>
      </div>
      <div className="list">
        <h2 className="list_title">{identity_verification_upload_pass_info.title}</h2>
        <ul>
          {identity_verification_upload_pass_info.list.map((el: string) => (<li key={el}>{el}</li>))}
        </ul>
      </div>
    </div>
  );
};

export default observer(GetDocuments);
