import { useEffect, useState } from 'react';

import Checkbox from 'components/InputChechbox';
import { observer } from 'mobx-react-lite';
import store from 'store/mainStore';
import { Link } from 'react-router-dom';
import { meData, signin } from 'configs';
import useRequest from 'hooks/useRequest';
import ButtonAnt from 'components/ButtonAnt';
import LocalStorage from 'utils/LocalStorage';
import { Form, Input } from 'antd';
import ChangePassword from 'components/modals/ChangePassword';
import { emailRule } from 'components/forms/InputItem/rules';

export type ILogin = {
    email: string;
    password: string;
}
const Auth = () => {
  const { langPack, updateToken, jwtAccess, jwtRefresh, setAuth } = store;
  const {
    user_sign_in,
    enter_email,
    enter_password,
    user_btn_sign_in,
    user_chb_remember_me,
    sign_in_no_account_sign_up,
    sign_in_forgot_password,
  } = langPack;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toogleModal = () => {
    setIsOpen(!isOpen);
  };
  const {
    fetch, isSuccess, isLoading, requestResult: tokenData,
  } = useRequest(signin);

  const {
    fetch: getAuthData, requestResult: userData, isSuccess: isGotData,
  } = useRequest(meData);

  const submit = (values: any) => {
    LocalStorage.remove('a');
    LocalStorage.remove('r');
    fetch(values);
  };

  useEffect(() => {
    if (isGotData && userData) {
      const body = {
        ...userData,
        jwtToken: tokenData,
      };
      setAuth(body);
    }
  }, [isGotData, userData, tokenData, setAuth]);

  useEffect(() => {
    if (isSuccess && tokenData) {
      updateToken(tokenData.accessToken, 'access');
      updateToken(tokenData.refreshToken, 'refresh');
    }
  }, [isSuccess, tokenData, updateToken]);

  useEffect(() => {
    if (!jwtAccess || !jwtRefresh || !isSuccess || isGotData) return;
    getAuthData();
  }, [jwtAccess, jwtRefresh, isSuccess, getAuthData, isGotData]);

  return (
    <div className="signinForm">
      <ChangePassword isOpen={isOpen} toogleModal={toogleModal} isReset />

      <div className="center">
        <h1>{user_sign_in}</h1>
      </div>
      <Form
        name="loginForm"
        initialValues={{ remember: true }}
        onFinish={submit}
        autoComplete="off"
        style={{ width: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '0px' }}
      >
        <div className="signinForm_box">
          <Form.Item name="email" rules={[emailRule]} required>
            <Input
              bordered={false}
              maxLength={50}
              className="inp"
              placeholder={enter_email}
            />
          </Form.Item>
          <Form.Item name="password" required>

            <div className="inputBox">
              <Input.Password
                placeholder={enter_password}
                maxLength={50}
                style={{ outline: 'none' }}
              />
            </div>
          </Form.Item>
          <Form.Item>
            <ButtonAnt htmlType="submit" large type="primary" loading={isLoading}>{user_btn_sign_in}</ButtonAnt>
          </Form.Item>
          <div className="signinForm_sub">
            <Checkbox label={user_chb_remember_me} />
            <div style={{ display: 'flex', width: '180px', cursor: 'pointer', color: '#1890ff' }} onClick={toogleModal}>{sign_in_forgot_password}</div>
          </div>
          <div className="divider mt20" />
          <div className="mt20" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
              {sign_in_no_account_sign_up.note}
            </span>
            <Link to="/signup">{sign_in_no_account_sign_up.action}</Link>
          </div>
        </div>

      </Form>
    </div>
  );
};
export default observer(Auth);
