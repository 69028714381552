import { FC, useState, useEffect } from 'react';

import { Input, Modal } from 'antd';
import { InputProps } from 'antd/lib/input';
import ButtonAnt from 'components/ButtonAnt';
import { confirmEmailSet, confirmEmailGet } from 'configs';
import useRequest from 'hooks/useRequest';
import store from 'store/mainStore';

import { FormItem, FormItemProps } from './FormItem';
import { emailRule } from './rules';

export type EmailItemProps = FormItemProps & Pick<InputProps, 'disabled'> & {
    value?: string;
    uneditable: boolean;
    placeholder?: string;
    verification?: boolean;
    onChange?: any;
  }

// TODO SET ERROR STATE
export const EmailItem: FC<EmailItemProps> = ({
  disabled, onChange, required, value, label, uneditable, placeholder, verification, ...rest
}) => {
  const { langPack } = store;

  const [mail, setMail] = useState<string>('');
  const [vcodeEmail, setVcodeEmail] = useState<number | null | undefined>();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    user_email_verify_time,
    user_email_verify_get,
    user_email_verify_error_wrong,
    send_code,
    confirm,
    user_email_verify_code,
    user_btn_verify,
    user_btn_cancel,
    user_email_verification,
  } = langPack;

  const {
    fetch, isLoading, isSuccess, isError, dropState: dropConfirm,
  } = useRequest(confirmEmailSet);

  const {
    fetch: getCode, isLoading: isLoadingGetCode, isSuccess: isSuccessGetCode, dropState,
  } = useRequest(confirmEmailGet);

  const confirmaEmailGetHandle = () => {
    getCode({
      verifyFor: mail,
    });
  };

  function validate(email: string) {
    const reg = /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/;
    return reg.test(email);
  }

  const confirmaEmailSetHandle = () => {
    if (!isSuccessGetCode) return;
    fetch({
      verifyFor: mail,
      code: vcodeEmail,
    });
  };

  const onChangehandler = (val: string) => {
    onChange && onChange(val);
    setMail(val);
  };
  const onChangehandlerVcode = (val: number) => {
    setVcodeEmail(val);
  };

  const toogleModal = () => {
    // if (!mail || !validate(mail)) return;
    // if (!isOpen) confirmaEmailGetHandle();
    setIsOpen(!isOpen);
  };

  const closeModal = () => {
    setVcodeEmail(undefined);
    setIsOpen(false);
    dropState();
    dropConfirm();
  };

  useEffect(() => {
    isSuccess && setIsOpen(false);
  }, [setIsOpen, isSuccess]);

  return (
    <div className="inputBox">
      {verification && (
      <Modal
        visible={isOpen}
        onCancel={toogleModal}
        destroyOnClose
        title={user_email_verification}
        footer={[
          <ButtonAnt
            onClick={() => (!isSuccessGetCode ? confirmaEmailGetHandle() : confirmaEmailSetHandle())}
            // disabled={isSuccessGetCode !mail || mail?.length < 6 || isLoadingGetCode || isLoading}
            type="primary"
            loading={isLoading || isLoadingGetCode}
          >
            {isSuccessGetCode ? user_btn_verify : send_code}
          </ButtonAnt>,
          <ButtonAnt
            onClick={closeModal}
          >
            {user_btn_cancel}
          </ButtonAnt>,
        ]}
      >
        <div>
          <div className="w80 centred" style={{ width: '100%', marginBottom: '10px' }}>
            <span style={{ fontSize: '25px', fontWeight: 600 }}>{mail || '---'}</span>
            <div
              className="noteEmailCodeSend"
              style={{ color: isSuccessGetCode ? 'green' : 'white' }}
            >
              { user_email_verify_get}
            </div>
          </div>
          <div className="w80 centred" style={{ width: '100%', paddingBottom: '10px' }}>
            <span style={{ fontSize: '15px', fontWeight: 600 }}>{user_email_verify_code}</span>
          </div>
          <div className="w80 centred" style={{ width: '100%', paddingBottom: '30px' }}>
            <Input
              name="vcodeEmail"
              type="number"
              onChange={(e: any) => onChangehandlerVcode(e.target.value)}
              value={vcodeEmail || ''}
              style={{ width: '200px', padding: '20px', fontWeight: 600, textAlign: 'center' }}
            />
            <div className="noteEmailCodeSend" style={{ color: !isError ? 'white' : 'red', marginTop: '10px' }}>{user_email_verify_error_wrong}</div>
            <div className="note">{user_email_verify_time}</div>
          </div>
        </div>
      </Modal>
      )}
      <div className="customInput_label" style={{ fontSize: uneditable ? '14px' : '' }}>{label}</div>
      {uneditable
        ? (
          <span>{value || '---'}</span>)
        : (
          <FormItem {...rest} required={required} rules={[emailRule]}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Input
                placeholder={placeholder || 'Например, info@mail.ru'}
                disabled={disabled || isSuccess}
                bordered={false}
                onChange={(e: any) => onChangehandler(e.target.value)}
                autoComplete="email"
                type="email"
                name="email"
                id="login"
              />
              {verification && <ButtonAnt type="primary" onClick={toogleModal} disabled={!validate(mail) || isSuccess}>{confirm}</ButtonAnt>}
            </div>
          </FormItem>
        )}
    </div>
  );
};
