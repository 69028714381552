import { useEffect, useRef } from 'react';

import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import store from 'store/mainStore';
import Auth from 'pages/Auth';
import './assets/main.scss';
import Header from 'components/header/Header';
import PrivateRoute from 'components/PrivateRoute';
import Blinker from 'components/Blinker';
// import IdentyVerification from 'pages/IdentyVerification';
import SignUp from 'pages/SignUp';
import 'antd/dist/antd.css';
import { Layout } from 'antd';
import Dashboard from 'pages/Dashboard';
import Success from 'pages/Sucess';
import Reject from 'pages/Reject';
// import { DAppProvider } from '@usedapp/core';

const { Header: Head, Footer, Content } = Layout;

const App = () => {
  const {
    isAuth, isNewAcc, screenWidth, setScreenWidth,
  } = store;
  const wrapperRef = useRef<undefined | any>();

  useEffect(() => {
    const resize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [setScreenWidth]);

  useEffect(() => {
    if (!wrapperRef?.current) return;
    wrapperRef.current.style.width = `${screenWidth - 30}px`;
  }, [screenWidth]);

  return (
    <>
      <Blinker />
      <Layout style={{ backgroundColor: 'transparent' }}>
        <Head style={{ backgroundColor: 'transparent' }}><Header /></Head>
        <Content style={{ backgroundColor: 'transparent', maxWidth: '1128px', margin: '0 auto' }}>
          <Switch>
            <Route path="/signin">{isAuth ? <Redirect to={isNewAcc ? 'new_legal' : '/redeem'} /> : <Auth />}</Route>
            <Route path="/signup" component={SignUp} />
            <PrivateRoute path="/success/" auth={isAuth} new_acc={isNewAcc}><Success /></PrivateRoute>
            <PrivateRoute path="/reject/" auth={isAuth} new_acc={isNewAcc}><Reject /></PrivateRoute>
            <PrivateRoute path="/dashboard/:id" auth={isAuth} new_acc={isNewAcc}><Dashboard /></PrivateRoute>
            <PrivateRoute path="/dashboard/" auth={isAuth} new_acc={isNewAcc}><Dashboard /></PrivateRoute>
            <Route exact path="/">
              {isAuth && !isNewAcc ? <Redirect to="/dashboard" /> : <Redirect to="/signin" />}
            </Route>
            <Route exact path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
          <Footer style={{ backgroundColor: 'transparent', height: '100px' }} />
        </Content>
      </Layout>
    </>
  );
};

export default observer(App);
