import { FC } from 'react';

import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';

import { FormItem, FormItemProps } from './FormItem';

export type PasswordItemProps = FormItemProps & Pick<InputProps, 'disabled' | 'onChange'> & {
  value?: string;
  uneditable: boolean;
  placeholder?: string;
}
// eslint-disable-next-line max-len

export const PasswordItemNoFill: FC<PasswordItemProps> = ({
  disabled, onChange, required, value, label, uneditable, placeholder, style = { height: '30px', border: 'none' }, ...rest
}) => (
  <div className="inputBox">
    <div className="customInput_label" style={{ fontSize: uneditable ? '14px' : '' }}>{label}</div>
    {uneditable
      ? (
        <span>{value || '---'}</span>)
      : (
        <FormItem {...rest} required={required}>
          <Input placeholder={placeholder || 'Введите пароль'} disabled={disabled} style={style} onChange={onChange} className="noFillpassword" />
        </FormItem>
      )}
  </div>
);
