/* eslint-disable max-len */

import {
  useRef, useCallback, useState,
} from 'react';

import { Modal } from 'antd';
import store from 'store/mainStore';
import Webcam from 'react-webcam';
import ButtonAnt from 'components/ButtonAnt';

import './style.scss';

type Props = {
    isOpen: boolean;
    toogleModal: any;
    tag: string;
}

const videoConstraints = {
  width: 1000,
  height: 1500,
  facingMode: 'user',
};

const WebcamModal = ({ isOpen, toogleModal, tag }: Props) => {
  const webcamRef = useRef<any>(null);
  const [photo, setPhoto] = useState<string>('');
  const {
    langPack: {
      inf_page_pass_take_photo,
      identity_verification_btn_continue,
      order_btn_cancel,
      check_img_btn_retake,
    },
    setPayloadByKey,
  } = store;

  const drop = () => {
    setPhoto('');
  };

  const capture = useCallback(
    () => {
      const imageSrc = webcamRef?.current?.getScreenshot();
      setPhoto(imageSrc);
    },
    [webcamRef],
  );

  const setImage = () => {
    if (!photo) return;
    setPayloadByKey(tag, photo);
    setPhoto('');
    // whenImageReady(photo, tag);
    toogleModal();
  };

  return (
    <Modal
      visible={isOpen}
      title={inf_page_pass_take_photo}
      zIndex={960}
      style={{ top: '30px' }}
      onCancel={toogleModal}
      footer={[
        <ButtonAnt onClick={toogleModal}>{order_btn_cancel}</ButtonAnt>,
        <ButtonAnt type="primary" onClick={() => (photo ? drop() : capture())}>{photo ? check_img_btn_retake : inf_page_pass_take_photo}</ButtonAnt>,
        <ButtonAnt type="primary" disabled={!photo} onClick={setImage}>{identity_verification_btn_continue}</ButtonAnt>,
      ]}
    >
      <div className="webcamContainer">
        {photo ? (
          <img
            src={photo}
            alt="snap"
          />
        )
          : isOpen ? (
            <Webcam
              audio={false}
              height={500}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={500}
              screenshotQuality={1}
              videoConstraints={videoConstraints}
            />
          ) : null}
      </div>
    </Modal>

  );
};

export default WebcamModal;
