/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import store from 'store/mainStore';
import { observer } from 'mobx-react';
import {
  Alert,
  Form, Input, Modal, Tooltip,
} from 'antd';
import ButtonAnt from 'components/ButtonAnt';
import useRequest from 'hooks/useRequest';
import {
  setChangePasswordCode, getChangePasswordCode, getChangePasswordResetCode, setChangePasswordRestCode, checkChangePasswordResetCode,
} from 'configs';
import { PasswordItem } from 'components/forms/InputItem/PasswordItem';
import checkFields, { checkPasswords } from 'utils/checkFields';
import {
  confimPassword, goodPassword,
} from 'components/forms/InputItem/rules';
import { QuestionCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { EmailItem } from 'components/forms/InputItem/EmailItem';

import './style.scss';

type Props = {
    isOpen: boolean;
    toogleModal: any;
    isReset?: boolean;
}

const ChangePassword = ({ isOpen, toogleModal, isReset = false }: Props) => {
  const {
    userData,
    langPack: {
      sign_up_individual_password_confirm,
      old_password,
      new_password,
      enter_password,
      user_btn_cancel,
      change_password,
      user_email_check_verify_time,
      send_code,
      user_sign_in_email,
    },
  } = store;

  const [email, setEmail] = useState<string>(!isReset ? userData.email : '');
  const [stage, setStage] = useState<string>('email');

  const [vcodeEmail, setVcodeEmail] = useState<number | null | undefined>();
  const [form] = Form.useForm();

  const [p, setP] = useState<string>('');
  const [disableButton, setDisableButton] = useState<boolean>(false);
  // TODO OUT SCOPE
  const getIcon = (state: boolean) => (state ? <CheckOutlined style={{ color: '#7CFC00' }} /> : <CloseOutlined style={{ color: '#FF0000' }} />);

  const pcheck = (pass: string) => {
    const checks = checkPasswords(pass);
    return (
      <Tooltip
        placement="right"
        title={(
          <>
            <p>
              {getIcon(checks.is_s)}
              {' '}
              Lower case alphabets
            </p>
            <p>
              {getIcon(checks.is_b)}
              {' '}
              Upper case alphabets
            </p>
            <p>
              {getIcon(checks.is_d)}
              {' '}
              Numbers
            </p>
            <p>
              {getIcon(checks.is_sp)}
              {' '}
              Special Characters
            </p>
            <p>
              {getIcon(checks.is_long)}
              {' '}
              8 characters minimum length
            </p>
          </>
)}
      >
        {new_password}
        {' '}
        <QuestionCircleOutlined />
      </Tooltip>
    );
  };
  const {
    fetch, isSuccess, isLoading, isError, dropState: dropChange,
  } = useRequest(isReset ? setChangePasswordRestCode : setChangePasswordCode);

  const {
    fetch: getCode, isLoading: isLoadingGetCode, isSuccess: isSuccessGetCode, dropState, isError: isErroGetCode,
  } = useRequest(isReset ? getChangePasswordResetCode : getChangePasswordCode);

  const {
    fetch: checkCode, isLoading: isLoadingCheck, isSuccess: isSuccessCheck, dropState: dropStateCheck, isError: isErroCheckCode,
  } = useRequest(checkChangePasswordResetCode);

  const modalClose = () => {
    setVcodeEmail(undefined);
    form.resetFields();
    dropState();
    dropStateCheck();
    dropChange();
    toogleModal();
    setStage('email');
  };

  const checkCodeHandler = () => {
    checkCode({
      code: vcodeEmail,
      verifyFor: email,
    });
  };

  const changePasswordSetHandle = () => {
    if (!isSuccessGetCode) return;
    const obj = form.getFieldsValue();

    fetch({
      // code: vcodeEmail,
      confirmNewPassword: obj.confirmNewPassword,
      email,
      newPassword: obj.newPassword,
      oldPassword: obj.oldPassword,
    });
  };

  const validator = () => {
    const obj = form.getFieldsValue();
    const npassword = p === obj.confirmNewPassword;

    const isGood = checkFields(obj) && vcodeEmail?.toString().length === 6 && npassword;
    setDisableButton(!isGood);
  };

  const changePasswordGetHandle = () => {
    getCode({
      verifyFor: email,
    });
  };

  function validate(ma: string) {
    const reg = /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/;
    return reg.test(ma);
  }

  const handlerPass = (str: any) => {
    setP(str.target.value);
  };

  const onChangehandlerVcode = (val: number) => {
    setVcodeEmail(val);
  };

  const emailHandler = (data: any) => {
    setEmail(data);
  };

  // console.log('isError', isError, errorMessage, error);
  const getFlow = (s: string) => {
    switch (s) {
      case 'email':
        return (
          <div
            className="inputBox"
            style={{ display: 'flex', width: '340px' }}
          >
            {isReset ? (
              <>
                <div className="note" style={{ textAlign: 'center' }}>Укажиите Ваш email на него будет отправлен код для подтверждения смены пароля</div>
                <div className="customInput_label">{user_sign_in_email}</div>
                <EmailItem
                  name="email"
                  onChange={(d: any) => emailHandler(d)}
                  uneditable={false}
                  disabled={isSuccessGetCode || isLoadingGetCode}
                />
              </>

            )
              : <div className="note" style={{ textAlign: 'center' }}>{`На Ваш email ${email} будет отправлен код для подтверждения смены пароля`}</div>}

          </div>
        );
      case 'code':
        return (
          <>
            <div className="note" style={{ textAlign: 'center' }}>{`Введите код полученный на почту ${email}`}</div>
            <div className="w80 centred mt20" style={{ width: '100%', paddingBottom: '30px' }}>
              <Input
                name="vcodeEmail"
                type="number"
                onChange={(e: any) => onChangehandlerVcode(e.target.value)}
                value={vcodeEmail || ''}
                disabled={!isSuccessGetCode || isSuccessCheck}
                style={{ width: '200px', padding: '20px', fontWeight: 600, textAlign: 'center' }}
              />
              <div className="note mt20" style={{ textAlign: 'center' }}>{user_email_check_verify_time}</div>
            </div>
          </>

        );
      case 'setPassword':
        return (
          <Form
            name="basic"
            id="form"
            form={form}
            onFieldsChange={validator}
            initialValues={
            { email }
          }
            autoComplete="off"
            style={{
              width: '500px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '0px',
            }}
          >
            <div className="signinForm_box">
              {!isReset && (
              <PasswordItem
                label={old_password}
                name="oldPassword"
                placeholder={enter_password}
                required
                uneditable={false}
                disabled={!isSuccessGetCode || isReset && !isSuccessCheck}
              />
              )}
              <PasswordItem
                label={pcheck(p)}
                name="newPassword"
                placeholder={enter_password}
                required
                onChange={handlerPass}
                rules={[(goodPassword())]}
                uneditable={false}
                disabled={!isSuccessGetCode || isReset && !isSuccessCheck}
              />
              <PasswordItem
                label={sign_up_individual_password_confirm}
                name="confirmNewPassword"
                placeholder={enter_password}
                required
                rules={[(confimPassword(p))]}
                uneditable={false}
                disabled={!isSuccessGetCode || isReset && !isSuccessCheck}
              />
            </div>

          </Form>
        );
      default:
        return null;
    }
  };

  const getButtons = (s: string) => {
    switch (s) {
      case 'email':
        return {
          name: send_code,
          function: () => changePasswordGetHandle(),
        };
      case 'code':
        return {
          name: 'Проверить код',
          function: () => checkCodeHandler(),
        };
      case 'setPassword':
        return {
          name: change_password,
          function: () => changePasswordSetHandle(),
        };
      default:
        break;
    }
  };
  const buttonTitles = getButtons(stage);

  useEffect(() => {
    isSuccessGetCode && setStage('code');
  }, [isSuccessGetCode]);

  useEffect(() => {
    isSuccessCheck && setStage('setPassword');
  }, [isSuccessCheck]);

  useEffect(() => {
    if (isSuccess) {
      modalClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
  }, [email, isReset]);

  return (
    <Modal
      visible={isOpen}
      title={change_password}
      style={{ top: '100px' }}
      zIndex={901}
      destroyOnClose
      footer={[
        <ButtonAnt onClick={modalClose}>{user_btn_cancel}</ButtonAnt>,
        <ButtonAnt
          onClick={() => buttonTitles?.function()}
          // onClick={() => (!isSuccessGetCode ? changePasswordGetHandle() : !isSuccessCheck && isReset ? checkCodeHandler() : changePasswordSetHandle())}
          disabled={disableButton || isReset && !validate(email) || isLoadingGetCode || isLoading || isLoadingCheck}
          type="primary"
          // disabled={(isReset && !validate(email))}
          loading={isLoading || isLoadingGetCode || isLoadingCheck}
        >
          {buttonTitles?.name}
          {/* {!isSuccessGetCode ? send_code : !isSuccessCheck && isReset ? 'Проверить код' : change_password } */}
        </ButtonAnt>]}
      onCancel={toogleModal}
    >
      <div className="w80 centred" style={{ width: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {getFlow(stage)}
      </div>
      <div className="mt20" />
      { isErroGetCode || isErroCheckCode || isError ? <Alert message={isErroGetCode ? 'Не верно указан email' : isErroCheckCode ? 'Неправильный или просроченый код подтверждения' : isError ? 'Введены некорректные пароли' : null} type="error" showIcon /> : null}
    </Modal>

  );
};

export default observer(ChangePassword);
