/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import CustomRadioButton from 'components/customCheckbox/CustomRadioButton';
import AccountModal from 'components/modals/AccountModal';
import Checkbox from 'components/InputChechbox';
import store from 'store/mainStore';
import OrderModal from 'components/modals/RedeemOrderModal';
import { observer } from 'mobx-react-lite';
import { Form } from 'antd';
import ContentWrapper from 'components/Columns/Columns';
import InputItem from 'components/forms/InputItem/InputItem';
import { SelectItem } from 'components/forms/InputItem/SelectItem';
import ButtonAnt from 'components/ButtonAnt';
import useRequest from 'hooks/useRequest';
import { createOrderCFG } from 'configs';
import checkFields from 'utils/checkFields';
import { checkAddress, checkMinMax } from 'components/forms/InputItem/rules';
import { toJS } from 'mobx';

const Acquire = () => {
  const {
    isMobile, langPack: {
      order_amount,
      order_user_loan_agreement,
      order_check_fees,
      order_update_bank_accounts,
      here,
      order_acquire_wire_trans_notification,
      order_btn_create_order,
      order_choose_payment_method,
      order_user_wallet_address,
      order_network,
      attention,
      order_wire_transfer,
      order_payment_service,
      user_btn_cancel,
      choose_network,
      minAmount,
      maxAmount,
    },
    bankAccounts,
    defaultAccount,
    networks,
    paymentServices,
    companyAccounts,
  } = store;

  const [form] = Form.useForm();

  const { fetch, requestResult, isLoading, dropState } = useRequest(createOrderCFG);

  const [paymentProviderId, setPaymentProvider] = useState<number>(0);

  const [paymentMethodEnum, setPaymentM] = useState<string>('PAYMENT_SERVICE');

  const [networkId, setNetworkId] = useState<number>(0);

  const [userAccountId, setAcc] = useState<number>(0);

  const [agreeLoanAgreement, setAgreeLoanAgreement] = useState<boolean>(false);

  const [disableButton, setDisableButton] = useState<boolean>(true);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toogleModal = () => setIsOpen(!isOpen);

  const [isOpenOrder, setIsOpenOrder] = useState<boolean>(false);

  const toogleModalOrder = () => setIsOpenOrder(!isOpenOrder);

  const [limits, setLimits] = useState<any>({ min: 0, max: 0 });

  const validator = () => {
    const obj = form.getFieldsValue();
    const isGood = checkFields(obj);
    setDisableButton(!isGood);
  };

  const optionsPM = [
    {
      label: order_payment_service,
      value: 'PAYMENT_SERVICE',
    },
    {
      label: order_wire_transfer,
      value: 'WIRE_TRANSFER',
    },
  ];

  const optionsNetworks = networks.map((el: any) => ({ label: el.networkName, value: el.id, ...el }));

  const accounts = bankAccounts.map((el: any) => ({ label: el.accountNick, value: el.id, ...el }));

  const providers = paymentServices.map((el: any) => ({ label: el.providerName, value: el.id, ...el }));

  useEffect(() => {
    if (userAccountId === 0 && defaultAccount) setAcc(defaultAccount);
  }, [defaultAccount, userAccountId]);

  useEffect(() => {
    if (paymentProviderId === 0 && providers.length) setPaymentProvider(providers[0].value);
  }, [paymentProviderId, providers]);

  const sendData = (value: any) => {
    setIsOpenOrder(true);
    const { address, amount } = value;
    const body = {
      orderType: 'ACQUIRING',
      address,
      agreeLoanAgreement,
      amount: Number(amount),
      networkId,
      paymentMethodEnum,
      ...(paymentMethodEnum === 'PAYMENT_SERVICE' ? { paymentProviderId } : { userAccountId }),
    };

    fetch(body);
  };

  const clearForm = () => {
    form.resetFields();
    dropState();
    /* setPaymentProvider(0);
    setAcc(0); */
    setAgreeLoanAgreement(false);
    setPaymentM('PAYMENT_SERVICE');
    validator();
  };

  useEffect(() => {
    const a = toJS(bankAccounts);
    const isHaveAcc = a.find((el: any) => el.id === userAccountId);
    if (!isHaveAcc && defaultAccount !== userAccountId) {
      setAcc(defaultAccount);
      validator();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccounts, defaultAccount]);

  useEffect(() => {
    const isPS = paymentMethodEnum === 'PAYMENT_SERVICE';
    const ca = toJS(companyAccounts);
    const d = isPS ? providers.find((el: any) => el.id === paymentProviderId) : ca;
    const min = Number(d?.amountLowerBound || 0);
    const max = Number(d?.amountUpperBound || 0);

    setLimits({
      min,
      max,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentProviderId, userAccountId, paymentMethodEnum, networkId]);

  return (
    <div className="inpage">
      <AccountModal isOpen={isOpen} toogleModal={toogleModal} />
      <OrderModal isOpen={isOpenOrder} orderData={requestResult || {}} toogleModal={toogleModalOrder} isMobile={isMobile} clearForm={clearForm} />
      <ContentWrapper
        leftBlock={(
          <Form
            name="basic"
            form={form}
            // initialValues={{ network: firstNetwork }}
            onFinish={sendData}
            onFieldsChange={validator}
            id="acqForm"
            autoComplete="off"
          >
            <InputItem
              label={`${order_amount} ${minAmount}: ${limits.min} ${maxAmount}: ${limits.max}`}
              name="amount"
              type="number"
              required={false}
              disabled={limits.min > limits.max}
              placeholder={order_amount}
              uneditable={false}
              rules={[checkMinMax(limits.min, limits.max)]}
              maxLength={250}
            />
            <SelectItem
              label={order_network}
              options={optionsNetworks}
              name="networkId"
              placeholder={choose_network}
              onChange={(d: any) => setNetworkId(d)}
            />
            <InputItem
              label={order_user_wallet_address}
              name="address"
              required={false}
              placeholder={order_user_wallet_address}
              uneditable={false}
              rules={[(checkAddress())]}
              maxLength={250}
            />
            <SelectItem
              label={order_choose_payment_method}
              options={optionsPM}
              onChange={(data: string) => {
                setPaymentM(data);
                agreeLoanAgreement && setAgreeLoanAgreement(false);
              }}
              initialValue="PAYMENT_SERVICE"
              name="paymentMethodEnum"
              placeholder={order_choose_payment_method}
            />
            <div className="mt20" />
            {
              paymentMethodEnum === 'PAYMENT_SERVICE'
                ? <CustomRadioButton options={providers} onChange={setPaymentProvider} selected={(!paymentProviderId && providers.length) ? providers[0].value : paymentProviderId} />
                : (
                  accounts.length
                    ? (
                      <CustomRadioButton
                        options={accounts}
                        onChange={(data: number) => {
                          setAcc(data);
                          validator();
                        }}
                        selected={userAccountId}
                      />
                    ) : <div style={{ color: 'red' }}> Необходимо добаивть банковский аккаунт</div>
                )
            }
            {paymentMethodEnum !== 'PAYMENT_SERVICE'
              && (
              <div className="mt20">
                {order_update_bank_accounts}
                {' '}
                <span onClick={toogleModal} className="textBut">{here}</span>
              </div>
              )}
            <div className="mt20">
              {order_check_fees}
              {' '}
              <a href="https://liquid.holdings/fees" target="_blank" className="textBut" rel="noreferrer">{here}</a>
            </div>
            {paymentMethodEnum !== 'PAYMENT_SERVICE'
            && (
            <div className="mt20">
              <strong>{attention}</strong>
              {order_acquire_wire_trans_notification}
            </div>

            )}

          </Form>
        )}
        rightBlock={(
          <>
            <div style={{ display: 'flex', height: '200px' }} />
            <div>
              <Checkbox
                label={order_user_loan_agreement}
                checkBoxHandler={() => {
                  setAgreeLoanAgreement(!agreeLoanAgreement);
                  validator();
                }}
                checked={agreeLoanAgreement}
              />
              <div className="rbuttonSec_but">
                <ButtonAnt
                  htmlType="submit"
                  type="primary"
                  form="acqForm"
                  large
                  loading={isLoading}
                  disabled={limits.min > limits.max || disableButton || !agreeLoanAgreement || isOpenOrder || !accounts.length || (paymentMethodEnum === 'PAYMENT_SERVICE' ? paymentProviderId === 0 : userAccountId === 0)}
                >
                  {order_btn_create_order}

                </ButtonAnt>
                <ButtonAnt
                  onClick={clearForm}
                  large
                >
                  {user_btn_cancel}

                </ButtonAnt>
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default observer(Acquire);
