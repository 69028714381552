import { FC } from 'react';

import { InputProps } from 'antd/lib/input';
import { DatePicker, DatePickerProps } from 'antd';
import { LOCALE } from 'configs/locale';
import { observer } from 'mobx-react';
import store from 'store/mainStore';
import { Moment } from 'moment';

import { NOT_EMPTY, NO_RULE } from './rules';
import { FormItem, FormItemProps } from './FormItem';

export type DateTextItemProps = FormItemProps &
    Pick<InputProps, 'disabled' | 'onChange'> & {
        removeChar?: string;
        value?: string;
        uneditable: boolean;
        onlyBeforeDate?: Moment;
        placeholder?: string;
        defaultPickerValue?: Moment;
      }

const DateTextItem: FC<DateTextItemProps> = ({
  name,
  disabled,
  onChange,
  required,
  value,
  label,
  uneditable,
  onlyBeforeDate,
  placeholder,
  defaultPickerValue,
  ...rest
}) => {
  const onlyBeforeDateFilter = (beforeDate: Moment): DatePickerProps['disabledDate'] => d => {
    const cloned = d.clone();
    const date = beforeDate.clone();

    return cloned.endOf('day') >= date.endOf('day');
  };

  const { lang } = store;
  return (
    <div className="inputBox">
      <div className="customInput_label" style={{ fontSize: uneditable ? '14px' : '' }}>{label}</div>
      {uneditable
        ? (
          <span>{value || '---'}</span>)
        : (
          <FormItem
            {...rest}
            name={name}
            required={required}
            rules={[required ? NOT_EMPTY : NO_RULE]}
          >
            <DatePicker
              disabledDate={onlyBeforeDate ? onlyBeforeDateFilter(onlyBeforeDate) : undefined}
              style={{
                width: '100%',
                border: 'none',
                background: '#F7F9FB',
                padding: '5px 11px',
              }}
              defaultPickerValue={defaultPickerValue}
              locale={lang === 'RU' ? LOCALE : undefined}
              placeholder={placeholder}
              format="DD-MM-YYYY"
            />
          </FormItem>
        )}

    </div>
  );
};

export default observer(DateTextItem);
