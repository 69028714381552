/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';
import store from 'store/mainStore';
import { Form, Tabs } from 'antd';
import { signup } from 'configs';
import { useHistory } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import ButtonAnt from 'components/ButtonAnt';
import UserDataFields from 'components/AccountFields/UserDataFields';
import { CheckboxItem } from 'components/forms/InputItem/CheckboxItem';
import moment from 'moment';

const { TabPane } = Tabs;

const SignUp = () => {
  const [form] = Form.useForm();
  const {
    langPack, setAuth, isAuth, dropPayload,
  } = store;
  const {
    sign_up_individual,
    sign_up_legal_entity,
    user_consent_terms_of_service_privacy_policy,
    user_consent_receive_email_notifications,
    user_btn_create,
    user_btn_cancel,
  } = langPack;
  const history = useHistory();
  const {
    fetch, isLoading, requestResult, isSuccess,
  } = useRequest(signup);

  const [userType, setUserType] = useState<string>('USER_PHYSICAL');

  if (isAuth) history.replace('/');

  const onChange = (key: string) => {
    form.resetFields();
    setUserType(key);
  };

  useEffect(() => {
    if (isSuccess && requestResult) setAuth(requestResult);
  }, [isSuccess, requestResult, setAuth]);

  const submit = (values: any) => {
    const {
      confirmPassword,
      email,
      firstName,
      birthDate,
      inipa,
      passportIssuedBy,
      passportIssuedDate,
      passport,
      tin,
      legalCOR,
      legalName,
      legalPSRN,
      legalTin,
      legalType,
      isAgreePrivacyPolicy,
      isAgreeReceiveNotification,
      lastName,
      middleName,
      password,
      phone,
    } = values;
    const body = {
      address: [values.user_index, values.user_city, values.user_street, values.user_home, values.user_cabin || 0].join(', '),
      confirmPassword,
      email,
      firstName,
      ...(userType === 'USER_PHYSICAL' && {
        individualData: {
          birthDate: moment(birthDate).format('DD-MM-YYYY'),
          inipa,
          passportIssuedBy,
          passportIssuedDate: moment(passportIssuedDate).format('DD-MM-YYYY'),
          passportNumber: passport?.length > 3 ? passport?.split(' ')[2] : '',
          passportSeries: passport?.length > 3 ? `${passport?.split(' ')[0]} ${passport?.split(' ')[1]}` : '',
          tin,
        },
      }),
      ...(userType === 'USER_LEGAL' && {
        legalData: {
          legalAddress: [values.legal_index, values.legal_city, values.legal_street, values.legal_home, values.legal_cabin || 0].join(', '),
          legalCOR,
          legalName,
          legalPSRN,
          legalTin,
          legalType,
        },
      }),
      isAgreePrivacyPolicy,
      isAgreeReceiveNotification,
      isAgreeTermsService: true,
      lastName,
      middleName,
      password,
      phone,
      userType,
    };
    fetch(body);
  };

  return (
    <div className="container inpage">
      <Form
        onFinish={submit}
        id="regForm"
        form={form}
        autoComplete="off"
      >
        <div className="container">
          <div className="mt20" />
          <Tabs defaultActiveKey="2" onChange={onChange} destroyInactiveTabPane>
            <TabPane tab={sign_up_individual} key="USER_PHYSICAL">
              <UserDataFields />
            </TabPane>
            <TabPane tab={sign_up_legal_entity} key="USER_LEGAL">
              <UserDataFields legal />
            </TabPane>
          </Tabs>
        </div>
        <div className="inpage_box mt20">
          <CheckboxItem required name="isAgreeReceiveNotification" checkboxTitle={user_consent_receive_email_notifications} />
          <CheckboxItem required name="isAgreePrivacyPolicy" checkboxTitle={user_consent_terms_of_service_privacy_policy} />
        </div>
        <div>
          <div className="rbuttonSec_but">

            <ButtonAnt
              htmlType="submit"
              type="primary"
              form="regForm"
              large
              loading={isLoading}
            >
              {user_btn_create}
            </ButtonAnt>
            <ButtonAnt
              onClick={() => {
                dropPayload();
                history.replace('/');
              }}
              type="primary"
              large
            >
              {user_btn_cancel}
            </ButtonAnt>
          </div>

        </div>
      </Form>

    </div>
  );
};
export default observer(SignUp);
