import { useState } from 'react';

import store from 'store/mainStore';
import { observer } from 'mobx-react';
import Button from 'components/Button';
import WebcamModal from 'components/modals/WebcamModal';
import { ReactComponent as Pic } from 'assets/images/face.svg';
import { ReactComponent as Doc } from 'assets/images/doc.svg';
import { Upload, UploadProps } from 'antd';

const GetSelfie = () => {
  const { langPack, payload, deletePayloadByKey, setPayloadByKey } = store;
  const [open, setOpen] = useState<boolean>(false);

  const toogleModal = () => setOpen(!open);

  const {
    identity_verification_take_salfie_take_salfie,
    identity_verification_take_salfie_rules,
    or,
    inf_page_pass_take_photo,
    upload_photo,
  } = langPack;

  const propsSec: UploadProps = {
    name: 'passportRegImageBase64',
    accept: 'image/gif, image/jpeg, image/png',
    onChange(info: any) {
      const reader = new FileReader();
      reader.readAsDataURL(info.file);
      reader.onload = () => {
        setPayloadByKey('selfieImageBase64', reader.result);
        setPayloadByKey('isSelfieImageUploaded', true);
      };
    },
  };

  return (
    <div className="container centred">
      <WebcamModal isOpen={open} toogleModal={toogleModal} tag="selfieImageBase64" />
      <div className="mt20" />
      <div className="verification_container" style={{ flexDirection: 'column' }}>
        <div className="verification_selfieInformation">
          <div className="verification_selfieInformationImg">
            <Pic />
            <div className="verification_selfieInformationImgDoc">
              <Doc />
            </div>
          </div>
          <div className="verification_selfieInformationList">
            <div className="list">
              <h2 className="list_title">{identity_verification_take_salfie_take_salfie}</h2>
              <ul>
                {identity_verification_take_salfie_rules.map((el: string) => (<li key={el}>{el}</li>))}
              </ul>
            </div>
          </div>
        </div>

        <div className="verification_unitContainer">
          {/* <p>{identity_verification_inf_page_pass}</p> */}
          <div className="mt20" />
          <div className="verification_selfieInformationFrame">
            {payload.selfieImageBase64 && <img src={payload.selfieImageBase64} alt="selfieImageBase64" />}
            { !payload.selfieImageBase64 ? (
              <div className="verification_unit_button">
                <Button title={inf_page_pass_take_photo} action={toogleModal} />
                <span>{or}</span>
                <Upload {...propsSec} beforeUpload={() => false} showUploadList={false} style={{ width: '100%' }}>
                  <Button title={upload_photo} style={{ width: '100%' }} />
                </Upload>
              </div>
            )
              : <div className="verification_unit_delButton" onClick={() => deletePayloadByKey('selfieImageBase64')}>x</div>}
          </div>
        </div>
      </div>

    </div>
  );
};

export default observer(GetSelfie);
