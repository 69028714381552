/* eslint-disable max-len */
const langPack = {
  EN:
    {
      user_sign_in: 'Sign in',
      user_sign_in_email: 'Email',
      user_sign_in_password: 'Password',
      user_btn_sign_in: 'Sign in',
      user_chb_remember_me: 'Remember me',
      user_create_account: 'Create an account',
      user_create_legal_entity_account: 'Create a legal entity account',
      user_first_name: 'First name',
      user_last_name: 'Last name',
      user_middle_name: 'Middle name',
      user_email: 'Email address',
      add_account: 'Add bank account',
      user_email_update: 'Update',
      user_tin: 'TIN',
      enter_email: 'Enter your email',
      enter_password: 'Enter your password',
      user_legal_entity_name: 'Legal entity',
      user_consent_terms_of_service_privacy_policy: 'By checking this box, I confirm that I have read the <a href="https://liquid.holdings/legal" target="_blank">Platform Rules, Investment Agreement Offer, Personal Data Processing Policy, Privacy Policy, Cookie Policy</a>, entirely accept them, and hereby want to create an account with Liquid Holdings LLC.',
      user_consent_receive_email_notifications: 'By checking this box I confirm I wish to receive email notifications. Your email address is necessary to notify you about the activity on your account.',
      user_btn_create: 'Create',
      ser_btn_confirm: 'Confirm',
      user_btn_cancel: 'Cancel',
      data_send: {
        title: 'Data sent',
        description: 'As soon as the verification is passed, you will have access to all the functions of the platform',
      },
      data_not_send: {
        title: 'Data not sent',
        description: 'Check if you have all the images required for registration',
      },
      data_ready_send: {
        title: 'Data ready to send',
        description: "If you are sure that the data is correct, click 'Confirm'",
      },
      user_email_verification: 'Email verification',
      user_email_verify_code: 'Verification code',
      user_email_verify_time: '30 minutes to verify your email',
      user_email_check_verify_time: '30 minutes to enter verification code',
      user_email_verify_get: 'Confirmation code sent to the specified address',
      user_email_verify_error_get: 'Error sending code to the specified address',
      user_email_verify_error_wrong: 'You entered an invalid or expired code, please try again',
      user_email_not_verify: 'Verification not passed',
      user_btn_verify: 'Verify',
      user_my_account: 'My account',
      member_number: 'Member number',
      user_bank_accounts: 'Bank accounts (maximum 3)',
      user_bank_account_add: 'Add',
      user_bank_account_remove: 'Remove',
      user_bank_account_remove_que: 'Are you sure you want to delete this account?',
      user_bank_account_default_que: 'Are you sure set this account by default?',
      user_bank_account_nickname: 'Account nickname',
      user_bank_name: 'Bank name',
      user_bank_bic: 'Bank BIC',
      user_bank_account_number: 'Account number',
      user_consent_receive_notifications: 'By checking this box I confirm I wish to receive email notifications.',
      user_btn_close: 'Close',
      user_btn_save: 'Save',
      order_redeem: 'Redeem',
      order_acquire: 'Acquire',
      enter: 'Enter',
      order_amount: 'Amount',
      order_network: 'Network',
      order_user_wallet_address: 'Address',
      order_redeem_btn_conn_metamask: 'Connect to MetaMask',
      order_user_loan_agreement: 'By checking this box, I confirm that I have read the <a href="https://liquid.holdings/legal" target="_blank">Investment Agreement</a> and the <a href="https://liquid.holdings/legal" target="_blank">Investment Agreement Offer</a> and hereby give my consent to the documents and agree to enter the Investment Agreement with Liquid Holdings LLC.',
      order_choose_payment_method: 'Choose payment method',
      order_wire_transfer: 'Bank wire transfer',
      order_payment_service: 'Payment service',
      order_my_bank_accounts: 'My bank accounts',
      order_update_bank_accounts: 'Update my bank accounts',
      order_check_fees: 'Check fees and upper and lower thresholds for the amount',
      order_acquire_wire_trans_notification: ' The selected bank account for wire transfer should be exactly the same one you will use for transferring money to Liquid Holdings.',
      order_btn_create_order: 'Create order',
      order_review_redeem: 'Review redeem order',
      order_review_acquire: 'Review acquire order',
      order_order: 'Order',
      order_member: 'Member',
      order_payment_method: 'Payment method',
      order_bank_account: 'Bank account',
      order_created: 'Created',
      order_state: 'State',
      order_lh_bank_info: 'Liquid Holdings bank information',
      order_lh_bank_name: 'Bank name',
      order_lh_bank_bic: 'Bank BIC',
      order_lh_bank_account_number: 'Account number',
      attention_que: 'Include your Order number (LHO-XXX) in the wire transaction text/memo. Payments without an Order number will not be accepted. You have 10 days to make a transaction.',
      order_btn_place_order: 'Place order',
      order_btn_cancel: 'Cancel',
      order_placed: 'Order placed successfully',
      order_not_placed: "Order can't be placed",
      order_activity: 'Activity',
      order_time: 'Time',
      order_order_number: 'Order number',
      order_type: 'Type',
      order_txid: 'TxID',
      order_trans_confirm: 'Transaction confirmations',
      order_due_date: 'Due date',
      here: 'here',
      attention: 'Attention:',
      identity_verification: 'Identity verification',
      identity_verification_upload_pass_rules: {
        title: 'You are about to upload your passport. Please ensure that:',
        list: [
          'You are 18+ years old',
          'This is your own passport - government-issued document that is not expired',
          'This is an original document, not a scan or copy',
          'Remove any card holders or covers to avoid reflections or blur',
          'Place documents against a solid-colored background',
        ],
      },
      identity_verification_upload_pass_info: {
        title: 'Ensure that the document meet all the standard below:',
        list: [
          'Readable, clear and well-lit',
          'Not reflective, not blurry',
          'No black and white images, not edited',
          'Document is placed against a solid-colored background',
        ],
      },
      identity_verification_inf_page_pass: 'Information page of passport',
      identity_verification_residence_reg_page_pass: 'Residence registration page of passport',
      identity_verification_btn_continue: 'Continue',
      sign_up_individual: 'Individual',
      inf_page_pass_take_photo: 'Take photo',
      or: 'or',
      upload_photo: 'Upload photo',
      sign_up_legal_entity: 'Legal entity',
      my_account_individual_bank_account_nickname: 'Account nickname',
      my_account_individual_birthdate: 'Birthdate',
      my_account_individual_INIPA: 'INIPA',
      my_account_individual_member_number: 'Member number',
      my_account_individual_passport_issued_by: 'Passport issued by',
      my_account_individual_passport_issued_date: 'Passport issue date',
      my_account_individual_passport_number: 'Passport number',
      my_account_individual_passport_series: 'Passport series',
      my_account_individual_phone: 'Phone',
      my_account_individual_registered_address: 'Registration address',
      sign_up_individual_password: 'Password',
      sign_up_individual_password_confirm: 'Confirm password',
      change_password: 'Change password',
      confirm: 'Confirm',
      sign_up_legal_entity_actual_address: 'Actual address',
      sign_up_legal_entity_address: 'Legal address',
      sign_up_legal_entity_COR: 'COR',
      sign_up_legal_entity_name: 'Legal entity name',
      sign_up_legal_entity_type: 'Legal form of organization',
      sign_up_legal_entity_TIN: 'TIN',
      sign_up_legal_entity_PSRN: 'PSRN',
      back: 'Go back',
      sign_in_no_account_sign_up: {
        note: 'Don’t you have an account?',
        action: 'Sign up',
      },
      sign_in_forgot_password: 'Forgot password?',
      check_img_btn_retake: 'Retake',
      identity_verification_take_salfie_take_salfie: 'Take a selfie with your passport',
      identity_verification_take_salfie_rules: [
        'Use the camera on your mobile device to take a selfie of yourself with your passport',
        'Hold your passport close to, but not obstructing, your face when taking the photograph',
        'Hold your phone at arms length and take the photo straight on (ie. not looking up or down)',
        'Make sure the background isn’t too busy, the photo isn’t blurry, and your passport is readable',
        'Make sure your whole face is visible, centred, and your eyes are open and with a neutral expression',
        'Do not hide or alter parts of your face (No hats/beauty images/filters/mask)',
      ],
      phone_placeholder: 'For example, +74951234567',
      confirm_password_placeholder: 'Enter password confirmation',
      user_first_name_placeholder: 'Your name',
      user_last_name_placeholder: 'Your last name',
      user_middle_name_placeholder: 'Your middle name',
      inipa_placeholder: 'Specify your INIPA',
      inn_placeholder: 'Specify your TIN',
      my_account_individual_passport_issued_by_placeholder: 'Specify who issued the passport',
      my_account_individual_birthdate_placeholder: 'Your date of birth',
      my_account_individual_passport_number_placeholder: 'Specify the series and number of the passport',
      my_account_individual_passport_issued_date_placeholder: 'Specify the date of issue of the passport',
      index_placeholder: 'Index',
      city_placeholder: 'City',
      street_placeholder: 'Street',
      home_placeholder: 'House / building number',
      cabin_placeholder: 'Apartment / study / office',
      legal_type_placeholder: 'Specify the legal form',
      sign_up_legal_entity_name_placeholder: 'Enter company name',
      sign_up_legal_entity_COR_placeholder: 'Specify COR',
      sign_up_legal_entity_PSRN_placeholder: 'Specify PSRN',
      sign_up_legal_entity_TIN_placeholder: 'Specify TIN',
      send_code: 'Get code',
      email_not_unique: 'The email address already exists in the system',
      default_account: 'Default account',
      need_verification: 'To set account You need verificate your account',
      test_mode: 'The application is under testing, very soon you will be able to register and start using our service',
      per_page: 'Per page',
      choose_network: 'Choose network',
      connect_to_metamask: 'CONNECT TO',
      set_unlimited: 'Unlimited',
      add_allowance: 'Edit allowance',
      allowance_amount: 'Allowance amount',
      allowance: 'Allowance',
      how_match: 'How match',
      balance: 'Balance',
      request_pending: 'Request already pending. Open metamask plugin',
      edit_allowance: 'Edit allowance',
      all: 'All',
      last: 'Past',
      days: 'days',
      apply: 'Apply',
      old_password: 'Old password',
      new_password: 'New password',
      code_ver: 'Verification code send',
      due_date: 'Due date',
      need_to_install: 'You need install',
      need_to_add_ba: 'You need to add bank account',
      my_account: 'My profile',
      my_logout: 'Logout',
      ok: 'OK',
      go_to_activity: 'Go to activity',
      order_sucсess: ['Order', 'successfully paid'],
      verification_status: 'Verification status:',
      pending: 'Pending',
      in_progress: 'In progress',
      verified: 'Verified',
      not_verified: 'Not verifed',
      rejected: 'Rejected',
      start_verification: 'Start verification',
      minAmount: 'min.',
      maxAmount: 'max.',
    },
  RU: {
    choose_network: 'Выбрать сеть',
    email_not_unique: 'Адрес электронной почты уже существует в системе',
    test_mode: 'Приложение находится на стадии тестированя, совсем скоро Вы сможете зарегистрироваться и начать пользоваться нашим сервисом',
    need_verification: 'Для добавления банковских аккаунтов необходимо верифицировать аккаунт',
    user_sign_in: 'Вход в личный кабинет',
    user_sign_in_email: 'Почта',
    user_sign_in_password: 'Пароль',
    change_password: 'Сменить пароль',
    old_password: 'Старый пароль',
    new_password: 'Новый пароль',
    user_btn_sign_in: 'Войти',
    add_account: 'Добавить банковский аккаунт',
    default_account: 'Акканут по умолчанию',
    user_chb_remember_me: 'Запомнить меня',
    user_create_account: 'Создать учетную запись',
    user_create_legal_entity_account: 'Создать учетную запись юридического лица',
    user_first_name: 'Имя',
    user_last_name: 'Фамилия',
    user_middle_name: 'Отчество',
    user_email: 'Адрес электронной почты',
    user_email_update: 'Обновить',
    user_tin: 'ИНН',
    enter: 'Введите',
    enter_email: 'Введите Ваш адрес электронной почты',
    enter_password: 'Введите Ваш пароль',
    user_legal_entity_name: 'Юридическое лицо',
    user_consent_terms_of_service_privacy_policy: 'Установив этот флажок, я подтверждаю, что я прочитал <a href="https://liquid.holdings/legal" target="_blank">Правила Платформы, Оферту Инвестиционного договора, Политику по обработке персональных данных, Политику конфиденциальности, Политику использования куки файлов</a>, принимаю их целиком и полностью, и настоящим хочу создать учетную запись в компании ООО «Ликвидные Активы».',
    user_consent_receive_email_notifications: 'Установив этот флажок, я подтверждаю, что хочу получать уведомления по электронной почте. Ваш адрес электронной почты необходим, чтобы уведомлять вас об активности на вашей учетной записи.',
    user_btn_create: 'Создать',
    ser_btn_confirm: 'Пдтвердить',
    user_btn_cancel: 'Отмена',
    user_email_verification: 'Верификация электронной почты',
    user_email_verify_code: 'Код подтверждения',
    user_email_verify_time: '30 минуты, чтобы верифицировать свою электронную почту',
    user_email_check_verify_time: '30 минут для ввода кода подтверждения',
    user_email_verify_get: 'Код для подтверждения отправлен по указанному адресу',
    user_email_verify_error_get: 'Ошибка отправки кода по указанному адресу',
    user_email_verify_error_wrong: 'Вы ввели неверный или просроченый код, попробуйте еще раз',
    user_email_not_verify: 'Верификация не пройдена',
    user_btn_verify: 'Верифицировать',
    user_my_account: 'Личный кабинет',
    member_number: 'Номер пользователя',
    user_bank_accounts: 'Банковские счета (максимум 3)',
    user_bank_account_add: 'Добавить',
    user_bank_account_remove: 'Удалить',
    user_bank_account_remove_que: 'Вы уверены что хотите удалить этот аккаунт?',
    user_bank_account_nickname: 'Псевдоним учетной запись',
    user_bank_name: 'Наименование банка',
    user_bank_bic: 'БИК банка',
    user_bank_account_number: 'Номер расчетного счета',
    user_consent_receive_notifications: 'Установив этот флажок, я подтверждаю, что хочу получать уведомления по электронной почте.',
    user_btn_close: 'Закрыть',
    user_btn_save: 'Сохранить',
    order_redeem: 'Погашать',
    order_acquire: 'Приобретать',
    order_amount: 'Количество',
    order_network: 'Сеть',
    order_user_wallet_address: 'Адрес',
    order_redeem_btn_conn_metamask: 'Подключиться к МетаМаску',
    order_user_loan_agreement: 'Установив флажок, я подтверждаю, что ознакомился <a href="https://liquid.holdings/legal" target="_blank">Инвестиционным договором</a> и <a href="https://liquid.holdings/legal" target="_blank">Офертой инвестиционного договора</a> и настоящим выражаюю свое согласие с указанными документами и заключение Инвестиционного договора с ООО «Ликвидные активы».',
    order_choose_payment_method: 'Выберите способ оплаты',
    order_wire_transfer: 'Банковский перевод',
    order_payment_service: 'Платежный сервис',
    order_my_bank_accounts: 'Мои банковские счета',
    order_update_bank_accounts: 'Обновить мои банковские счета',
    order_check_fees: 'Проверьте сборы, а также верхний и нижний пороги суммы',
    order_acquire_wire_trans_notification: ' Выбранный банковский счет для банковского перевода должен быть точно таким же, который вы будете использовать для перевода денег в компанию Ликвидные Активы.',
    order_btn_create_order: 'Создать заказ',
    order_review_redeem: 'Просмотр заказa на погашение',
    order_review_acquire: 'Просмотр заказа на приобретение',
    order_order: 'Заказ',
    order_member: 'Пользователь',
    order_payment_method: 'Mетод оплаты',
    order_bank_account: 'Банковский счет',
    order_created: 'Создан',
    order_state: 'Состояние',
    order_lh_bank_info: 'Банковская информация компании Ликвидные Активы',
    order_lh_bank_name: 'Наименование банка',
    order_lh_bank_bic: 'БИК банка',
    order_lh_bank_account_number: 'Номер расчетного счета',
    attention_que: 'Включите номер вашего Заказа (LHO-XXX) в текст/памятку банковского перевода. Платежи без номера Заказа не принимаются. У вас есть 10 дней на совершение сделки.',
    order_btn_place_order: 'Разместить заказ',
    order_btn_cancel: 'Отмена',
    order_placed: 'Заказ успешно размещен',
    order_not_placed: 'Заказ не может быть размещен',
    order_activity: 'Aктивность',
    order_time: 'Время',
    order_order_number: 'Номер заказа',
    order_type: 'Тип',
    order_txid: 'TxID',
    order_trans_confirm: 'Подтверждения транзакции',
    order_due_date: 'Срок оплаты',
    here: 'здесь',
    back: 'Назад',
    attention: 'Внимание:',
    identity_verification: 'Проверка личности',
    identity_verification_upload_pass_rules: {
      title: 'Вы собираетесь загрузить свой паспорт. Убедитесь, что:',
      list: [
        'Вам 18+ лет',
        'Это ваш собственный  паспорт - государственного образца, срок действия которого не истек',
        'Это оригинальный документ, а не скан или копия',
        'Снимите все держатели карт или обёртку, чтобы избежать отражений или размытия',
        'Размещайте документы на однотонном фоне',
      ],
    },
    identity_verification_upload_pass_info: {
      title: 'Убедитесь, что документ соответствует всем приведенным ниже стандартам:',
      list: [
        'Читабельный, четкий и хорошо освещенный',
        'Не отражающий, не размытый',
        'Без черно-белых изображений, без обработки',
        'Документ размещается на однотонном фоне',
      ],
    },
    identity_verification_inf_page_pass: 'Информационная страница паспорта',
    identity_verification_residence_reg_page_pass: 'Страница регистрации по месту жительства в паспорте',
    identity_verification_btn_continue: 'Продолжить',
    inf_page_pass_take_photo: 'Сфотографировать',
    or: 'или',
    upload_photo: 'Загрузить фото',
    data_send: {
      title: 'Данные отправлены',
      description: 'Как только верификация будет пройдена, Вам будут доступны все функции платформы',
    },
    data_not_send: {
      title: 'Данные не отправлены',
      description: 'Проверьте наличие всех изображений необходимых для регистрации',
    },
    data_ready_send: {
      title: 'Данные готовы к отправке',
      description: "Если Вы уверены в правильности данных нажмите 'Подтвердить'",
    },
    sign_up_individual: 'Физическое лицо',
    sign_up_legal_entity: 'Юридическое лицо',
    user_bank_account_default_que: 'Сделать этот аккаунт по умолчанию?',
    my_account_individual_bank_account_nickname: 'Псевдоним учетной запись',
    my_account_individual_birthdate: 'Дата рождения',
    my_account_individual_INIPA: 'СНИЛС',
    my_account_individual_member_number: 'Номер пользователя',
    my_account_individual_passport_issued_by: 'Кем выдан паспорт',
    my_account_individual_passport_issued_date: 'Дата выдачи паспорта',
    my_account_individual_passport_number: 'Номер паспорта',
    my_account_individual_passport_series: 'Серия паспорта',
    my_account_individual_phone: 'Телефон',
    my_account_individual_registered_address: 'Адрес регистрации',
    sign_up_individual_password: 'Пароль',
    sign_up_individual_password_confirm: 'Подтвердите пароль',
    confirm: 'Подтвердить',
    sign_up_legal_entity_actual_address: 'Фактический адрес',
    sign_up_legal_entity_address: 'Юридический адрес',
    sign_up_legal_entity_name: 'Наименование юр. лица',
    sign_up_legal_entity_type: 'Организационно-правовая форма',
    sign_up_legal_entity_TIN: 'ИНН',
    sign_up_legal_entity_PSRN: 'ОГРН',
    sign_up_legal_entity_COR: 'КПП',
    sign_in_no_account_sign_up: {
      note: 'У вас нет учетной записи?',
      action: 'Зарегистрироваться',
    },
    sign_in_forgot_password: 'Забыли пароль?',
    check_img_btn_retake: 'Переснять',
    identity_verification_take_salfie_take_salfie: 'Сделать селфи со своим паспортом',
    identity_verification_take_salfie_rules: [
      'Используйте камеру на своем мобильном устройстве, чтобы сделать селфи со своим паспортом ',
      'Держите паспорт рядом с лицом, но не закрывайте его во время фотосъемки',
      'Держите телефон на расстоянии вытянутой руки и сделайте снимок прямо (т.е. не глядя вверх или вниз)',
      'Убедитесь, что фон не слишком занят, фотография не размыта, а ваш паспорт можно прочитать',
      'Убедитесь, что все ваше лицо видно, находится по центру, а глаза открыты и имеют нейтральное выражение',
      'Не скрывайте и не изменяйте части лица (без головных уборов/красивых изображений/фильтров/маски)',
    ],
    phone_placeholder: 'Например, +74951234567',
    confirm_password_placeholder: 'Введите подтверждение пароля',
    user_first_name_placeholder: 'Вашe имя',
    user_last_name_placeholder: 'Ваша фамилия',
    user_middle_name_placeholder: 'Ваше отчество',
    inipa_placeholder: 'Укажите Снилс',
    inn_placeholder: 'Укажите ИНН',
    my_account_individual_passport_issued_by_placeholder: 'Укажите кем выдан паспорт',
    my_account_individual_birthdate_placeholder: 'Ваша дата рождения',
    my_account_individual_passport_number_placeholder: 'Укажите серию и номер паспорта',
    my_account_individual_passport_issued_date_placeholder: 'Укажите дату выдачи паспорта',
    index_placeholder: 'Индекс',
    city_placeholder: 'Город',
    street_placeholder: 'Улица',
    home_placeholder: 'Номер дома / корпуса',
    cabin_placeholder: 'Квартира / кабинет / офис',
    legal_type_placeholder: 'Укажите организационно правовую форму',
    sign_up_legal_entity_name_placeholder: 'Укажите название компании',
    sign_up_legal_entity_COR_placeholder: 'Укажите КПП',
    sign_up_legal_entity_PSRN_placeholder: 'Укажите ОГРН',
    sign_up_legal_entity_TIN_placeholder: 'Укажите ИНН',
    send_code: 'Получить код',
    per_page: 'На странице',
    connect_to_metamask: 'ПОДКЛЮЧИТСЯ К',
    set_unlimited: 'Без ограничений',
    add_allowance: 'Добавить разрешение',
    allowance_amount: 'Разрешенная сумма',
    how_match: 'Введите сумму',
    balance: 'Баланс',
    allowance: 'Разрешено',
    request_pending: 'Запрос уже находится на рассмотрении. Открыть плагин metamask',
    edit_allowance: 'Изменить разрешения',
    all: 'Все',
    last: 'За',
    days: 'дней',
    apply: 'Применить',
    email: 'Код подтверждения отправлен',
    code_ver: 'Код отправлен',
    due_date: 'Cрок оплаты',
    need_to_install: 'Необходимоу установить ',
    need_to_add_ba: 'Необходимо добаивть банковский аккаунт',
    my_account: 'Мой профиль',
    my_logout: 'Выход',
    ok: 'OK',
    go_to_activity: 'Список заказов',
    order_sucсess: ['Заявка по заказу', 'успешно оплачена'],
    verification_status: 'Статус верификации:',
    pending: 'Ожидается',
    in_progress: 'В процессе',
    verified: 'Верифицирован',
    not_verified: 'Не верифицирован',
    rejected: 'Отклонено',
    start_verification: 'Начать верификацию',
    minAmount: 'мин.',
    maxAmount: 'макс.',
  },
};

const getContent = lang => langPack[lang];

export default getContent;
