import React from 'react';

import {
  FormItemProps, Input, InputProps,
} from 'antd';

import './style.scss';
import { FormItem } from './FormItem';
import { NOT_EMPTY } from './rules';

const { TextArea } = Input;

type Props = FormItemProps & Pick<InputProps, 'disabled' | 'onChange' | 'placeholder' > & {
    value?: any;
    name: string;
    uneditable?: boolean;
    label?: any;
    className?: string;
    type?: string;
    prefix?: string;
    multiline?: boolean;
    maxLength?: number;
    minLength?: number;
}

const InputItem = ({
  disabled,
  onChange,
  required = false,
  prefix,
  placeholder,
  multiline = false,
  maxLength,
  minLength,
  rules = [],
  uneditable,
  type,
  value,
  label,
  ...rest
}: Props) => (
  <div className="inputBox">
    <div className="customInput_label" style={{ fontSize: uneditable ? '14px' : '' }}>{label}</div>
    {uneditable
      ? (
        <span>{value || '---'}</span>)
      : (
        <FormItem {...rest} required={required} rules={[NOT_EMPTY, ...rules]}>
          {multiline ? (
            <TextArea
              rows={3}
              bordered={false}
              placeholder={placeholder}
              maxLength={maxLength}
              minLength={minLength}
              disabled={disabled}
            />
          ) : (
            <Input
              bordered={false}
              value={value}
              type={type}
              prefix={prefix}
              autoComplete="off"
              onChange={onChange}
              disabled={disabled}
              placeholder={placeholder}
              maxLength={maxLength}
              minLength={minLength}
            />
          )}

        </FormItem>
      )}
  </div>

);

export default InputItem;
