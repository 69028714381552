import {
  LikeOutlined,
} from '@ant-design/icons';
import { observer } from 'mobx-react';
import './style.scss';
import { Result } from 'antd';
import store from 'store/mainStore';

type Props = {
  isSuccess: boolean;
  isError: boolean;
}
const SendVerification = ({ isSuccess, isError }: Props) => {
  const { langPack } = store;
  const {
    data_send,
    data_not_send,
    data_ready_send,
  } = langPack;
  return (
    <div className="container centred">
      <div className="mt20 centredDiv">
        {
        isSuccess ? (
          <Result
            status="success"
            title={data_send.title}
            subTitle={data_send.description}
          />
        )
          : isError ? (
            <Result
              status="error"
              title={data_not_send.title}
              subTitle={data_not_send.description}
            />
          )
            : (
              <Result
                icon={<LikeOutlined />}
                title={data_ready_send.title}
                subTitle={data_ready_send.description}
              />
            )
      }
      </div>
    </div>
  );
};

export default observer(SendVerification);
