/* eslint-disable max-len */
import { useState } from 'react';

import { observer } from 'mobx-react-lite';
import store, { UData } from 'store/mainStore';
import InputItem from 'components/forms/InputItem/InputItem';
import { PhoneItem } from 'components/forms/InputItem/PhoneItem';
import { EmailItem } from 'components/forms/InputItem/EmailItem';
import DateTextItem from 'components/forms/InputItem/DateTextItem';
import moment from 'moment';
import { NumberItem } from 'components/forms/InputItem/NumberItem';
import {
  getMaskOfLength, INIPA_MASK, MASK_PLACEHOLDER, PASS_MASK,
} from 'components/forms/InputItem/masks';
import {
  confimPassword, goodPassword, INIPA_RULE, PASSPORT_RULE,
} from 'components/forms/InputItem/rules';
import { QuestionCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { checkPasswords } from 'utils/checkFields';
import { PasswordItemNoFill } from 'components/forms/InputItem/PasswordItemNoFill';

type Props = {
    legal?: boolean;
    getData?: Function;
    uneditable?: boolean;
    outData?: UData;
    errors?: ErrData[];
}

type ErrData = {
  field: string;
  message: string;
}

const UserDataFields = ({ legal = false, uneditable = false, outData }: Props) => {
  const { langPack, payload } = store;
  const data = uneditable ? outData : payload;
  const [p, setP] = useState<string>('');

  const {
    user_middle_name,
    user_first_name,
    user_last_name,
    user_email,
    enter_email,
    enter_password,
    my_account_individual_birthdate,
    my_account_individual_INIPA,
    my_account_individual_passport_issued_by,
    my_account_individual_passport_issued_date,
    my_account_individual_passport_number,
    my_account_individual_passport_series,
    my_account_individual_phone,
    my_account_individual_registered_address,
    sign_up_individual_password,
    sign_up_individual_password_confirm,
    sign_up_legal_entity_actual_address,
    sign_up_legal_entity_address,
    sign_up_legal_entity_COR,
    sign_up_legal_entity_name,
    sign_up_legal_entity_type,
    sign_up_legal_entity_TIN,
    sign_up_legal_entity_PSRN,
    phone_placeholder,
    confirm_password_placeholder,
    user_first_name_placeholder,
    user_last_name_placeholder,
    user_middle_name_placeholder,
    inipa_placeholder,
    inn_placeholder,
    my_account_individual_passport_issued_by_placeholder,
    my_account_individual_birthdate_placeholder,
    my_account_individual_passport_number_placeholder,
    my_account_individual_passport_issued_date_placeholder,
    index_placeholder,
    city_placeholder,
    street_placeholder,
    home_placeholder,
    cabin_placeholder,
    legal_type_placeholder,
    sign_up_legal_entity_name_placeholder,
    sign_up_legal_entity_COR_placeholder,
    sign_up_legal_entity_PSRN_placeholder,
    sign_up_legal_entity_TIN_placeholder,
  } = langPack;

  const DEFAULT_CLASSNAME = uneditable ? 'inpage_boxUneditable' : 'inpage_box';

  const Address = ({ values, prefix, label, uneditableData } : any) => (
    uneditable ? (
      <>
        <div className="customInput_label" style={{ fontSize: uneditable ? '14px' : '' }}>{label}</div>
        <span>{uneditableData}</span>
      </>
    ) : (
      <div className={DEFAULT_CLASSNAME}>
        <div className="customInput_label">{label}</div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <NumberItem
            style={{ width: '30%' }}
            name={`${prefix}_index`}
            mask={getMaskOfLength(6)}
            maxLength={6}
            minLength={6}
            value={values[0]}
            placeholder={index_placeholder}
            removeChar={MASK_PLACEHOLDER}
            required
            uneditable={uneditable}
          />
          <div style={{ display: 'flex', width: '20px' }} />
          <InputItem
            value={values[1]}
            placeholder={city_placeholder}
            name={`${prefix}_city`}
            style={{ width: '100%' }}
            uneditable={uneditable}
            maxLength={250}
            required
          />
        </div>
        <InputItem
          value={values[2]}
          placeholder={street_placeholder}
          name={`${prefix}_street`}
          style={{ width: '100%' }}
          uneditable={uneditable}
          maxLength={250}
          required
        />
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <InputItem
            value={values[3]}
            placeholder={home_placeholder}
            name={`${prefix}_home`}
            style={{ width: '30%' }}
            uneditable={uneditable}
            maxLength={250}
            required
          />
          <div style={{ display: 'flex', width: '20px' }} />
          <NumberItem
            style={{ width: '30%' }}
            name={`${prefix}_cabin`}
            value={values[4]}
            placeholder={cabin_placeholder}
            uneditable={uneditable}
          />
        </div>
      </div>
    )
  );
  const handlerPass = (str: any) => {
    setP(str.target.value);
  };

  const getIcon = (state: boolean) => (state ? <CheckOutlined style={{ color: '#7CFC00' }} /> : <CloseOutlined style={{ color: '#FF0000' }} />);

  const pcheck = (pass: string) => {
    const checks = checkPasswords(pass);
    return (
      <Tooltip
        placement="right"
        title={(
          <>
            <p>
              {getIcon(checks.is_s)}
              {' '}
              Lower case alphabets
            </p>
            <p>
              {getIcon(checks.is_b)}
              {' '}
              Upper case alphabets
            </p>
            <p>
              {getIcon(checks.is_d)}
              {' '}
              Numbers
            </p>
            <p>
              {getIcon(checks.is_sp)}
              {' '}
              Special Characters
            </p>
            <p>
              {getIcon(checks.is_long)}
              {' '}
              8 characters minimum length
            </p>
          </>
)}
      >
        {sign_up_individual_password}
        {' '}
        <QuestionCircleOutlined />
      </Tooltip>
    );
  };

  const gotDobLimit = () => {
    const date = new Date();
    date.setFullYear(new Date().getFullYear() - 18);
    return date;
  };

  return (
    <div className="inpage">
      {/*  */}
      <div className="fieldBox">
        <EmailItem
          label={user_email}
          name="email"
          verification
          placeholder={enter_email}
          uneditable={uneditable}
          required
        />
        <PhoneItem
          label={my_account_individual_phone}
          name="phone"
          placeholder={phone_placeholder}
          uneditable={uneditable}
          required
        />
        <PasswordItemNoFill
          label={pcheck(p)}
          name="password"
          placeholder={enter_password}
          uneditable={uneditable}
          required
          onChange={handlerPass}
          rules={[(goodPassword())]}
        />
        <PasswordItemNoFill
          label={sign_up_individual_password_confirm}
          name="confirmPassword"
          placeholder={confirm_password_placeholder}
          uneditable={uneditable}
          required
          rules={[(confimPassword(p))]}
        />
        <InputItem
          label={user_last_name}
          name="lastName"
          placeholder={user_last_name_placeholder}
          uneditable={uneditable}
          maxLength={250}
          required
        />
        <InputItem
          label={user_first_name}
          name="firstName"
          placeholder={user_first_name_placeholder}
          uneditable={uneditable}
          maxLength={250}
          required
        />
        <InputItem
          label={user_middle_name}
          name="middleName"
          uneditable={uneditable}
          maxLength={250}
          placeholder={user_middle_name_placeholder}
          required
        />
        {/* LEGAL */}
        {legal
          ? (
            <>
              <InputItem
                label={sign_up_legal_entity_type}
                name="legalType"
                uneditable={uneditable}
                maxLength={10}
                placeholder={legal_type_placeholder}
                required
              />
              <InputItem
                label={sign_up_legal_entity_name}
                name="legalName"
                uneditable={uneditable}
                maxLength={10}
                placeholder={sign_up_legal_entity_name_placeholder}
                required
              />
              <NumberItem
                label={sign_up_legal_entity_TIN}
                name="legalTin"
                mask={getMaskOfLength(12)}
                maxLength={12}
                minLength={12}
                removeChar={MASK_PLACEHOLDER}
                placeholder={sign_up_legal_entity_TIN_placeholder}
                required
                uneditable={uneditable}
              />
              <NumberItem
                label={sign_up_legal_entity_COR}
                name="legalCOR"
                mask={getMaskOfLength(9)}
                maxLength={9}
                minLength={9}
                placeholder={sign_up_legal_entity_COR_placeholder}
                removeChar={MASK_PLACEHOLDER}
                required
                uneditable={uneditable}
              />
              <NumberItem
                label={sign_up_legal_entity_PSRN}
                name="legalPSRN"
                mask={getMaskOfLength(10)}
                maxLength={10}
                minLength={10}
                placeholder={sign_up_legal_entity_PSRN_placeholder}
                removeChar={MASK_PLACEHOLDER}
                required
                uneditable={uneditable}
              />
              <Address
                prefix="legal"
                label={sign_up_legal_entity_address}
                uneditableData={data.legalAddress}
                values={[data.legal_index, data.legal_city, data.legal_street, data.legal_home, data.legal_cabin]}
              />
              <Address
                prefix="user"
                uneditableData={data.address}
                label={sign_up_legal_entity_actual_address}
                values={[data.user_index, data.user_city, data.user_street, data.user_home, data.user_cabin]}
              />
            </>
          )
          : (
            <>
              <DateTextItem
                label={`${my_account_individual_birthdate} (18+)`}
                name="birthDate"
                uneditable={uneditable}
                placeholder={my_account_individual_birthdate_placeholder}
                required
                defaultPickerValue={moment().subtract(18, 'y')}
                onlyBeforeDate={moment(gotDobLimit())}
              />
              <NumberItem
                label={my_account_individual_INIPA}
                name="inipa"
                placeholder={inipa_placeholder}
                mask={INIPA_MASK}
                maxLength={9}
                minLength={9}
                outRules={INIPA_RULE}
                removeChar={MASK_PLACEHOLDER}
                required
                uneditable={uneditable}
              />
              <NumberItem
                label={sign_up_legal_entity_TIN}
                name="tin"
                placeholder={inn_placeholder}
                mask={getMaskOfLength(12)}
                maxLength={12}
                minLength={12}
                removeChar={MASK_PLACEHOLDER}
                required
                uneditable={uneditable}
              />
              <NumberItem
                label={`${my_account_individual_passport_series} и ${my_account_individual_passport_number.toLowerCase()}`}
                name="passport"
                mask={PASS_MASK}
                maxLength={12}
                minLength={12}
                placeholder={my_account_individual_passport_number_placeholder}
                outRules={PASSPORT_RULE}
                removeChar={MASK_PLACEHOLDER}
                required
                uneditable={uneditable}
              />
              <InputItem
                label={my_account_individual_passport_issued_by}
                name="passportIssuedBy"
                placeholder={my_account_individual_passport_issued_by_placeholder}
                uneditable={uneditable}
                maxLength={350}
                required
              />
              <DateTextItem
                label={my_account_individual_passport_issued_date}
                name="passportIssuedDate"
                placeholder={my_account_individual_passport_issued_date_placeholder}
                uneditable={uneditable}
                required
                onlyBeforeDate={moment(Date.now())}
              />
              <Address
                prefix="user"
                uneditableData={data.address}
                label={my_account_individual_registered_address}
                values={[data.user_index, data.user_city, data.user_street, data.user_home, data.user_cabin]}
              />
            </>
          )}
      </div>

    </div>
  );
};
export default observer(UserDataFields);
