import React from 'react';

import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { updateOrderCFG } from 'configs';
import useRequest from 'hooks/useRequest';
import { useMount } from 'hooks';

const Reject = () => {
  const data = window.location.search.replace('?', '').split('&');
  const { fetch: updateFetch } = useRequest(updateOrderCFG);

  const payData = data.map((el: any) => ({
    key: el.split('=')[0],
    value: el.split('=')[1],
  }));
  const orderNumber = payData.find((el: any) => el.key === 'MERCHANT_ORDER_ID')?.value;

  useMount(() => {
    updateFetch({
      orderNumber,
      responseStatus: 'REJECTED',
      responseCode: 8080,
      orderType: 'ACQUIRING',
      paymentResponse: 'Reject by payment service',
      paymentMethodEnum: 'PAYMENT_SERVICE',
    });
  });
  return (
    <div className="mt20">
      <Result
        status="error"
        title={`Оплата заказа ${orderNumber} отменена`}
        extra={<Link to="/dashboard"><Button type="primary">OK</Button></Link>}
      />
    </div>

  );
};

export default Reject;
