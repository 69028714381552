import React from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { ReactComponent as AcquireIcon } from 'assets/images/acquie.svg';
import { ReactComponent as RedeemIcon } from 'assets/images/redeem.svg';
import { ReactComponent as ActivityIcon } from 'assets/images/activity.svg';
import Acquire from 'pages/Acquire';
import Redeem from 'pages/Redeem';
import { Tabs } from 'antd';
import store from 'store/mainStore';
import { observer } from 'mobx-react';

import Activity from './Activity';

const { TabPane } = Tabs;

const Dashboard = () => {
  const {
    langPack: {
      order_redeem,
      order_acquire,
      order_activity,
    },
  } = store;
  const history = useHistory();
  const { id } = useParams<any>();

  return (
    <Tabs
      defaultActiveKey={id || 'acquire'}
      onChange={(val: any) => history.replace(`/dashboard/${val}`)}
      destroyInactiveTabPane
    >
      <TabPane
        tab={(
          <div className="dashboard_menu">
            <AcquireIcon />
            {order_acquire}
          </div>
)}
        key="acquire"
      >
        <Acquire />
      </TabPane>
      <TabPane
        tab={(
          <div className="dashboard_menu">
            <RedeemIcon />
            {order_redeem}
          </div>
        )}
        key="redeem"
      >
        <Redeem />
      </TabPane>
      <TabPane
        tab={(
          <div className="dashboard_menu">
            <ActivityIcon />
            {order_activity}
          </div>
)}
        key="activity"
      >
        <Activity />
      </TabPane>
    </Tabs>
  );
};

export default observer(Dashboard);
