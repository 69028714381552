/* eslint-disable max-len */
import {
  action, computed, decorate, observable, toJS,
} from 'mobx';
import getContent from 'utils/localization';
import LocalStorage from 'utils/LocalStorage';

export type ILang = 'RU' | 'EN'

export const MOBILE_POINT_MENU = 768;
export const MOBILE_POINT_CONTENT = 414;

export type VerificationStatus = 'PENDING' | 'INPROGRESS' | 'VERIFIED' | 'FAILED' | null;

export type Verification = {
  creationDate: string;
  images: any[];
  notes: string;
  updateDate: string;
  verificationState: VerificationStatus;
}

export type PaymentServicesType = {
  active: boolean;
  amountLowerBound: number;
  amountUpperBound: number;
  id: number;
  note: string;
  providerName: string;
  providerParams: string;
  providerUrl: string;
}

export type NetworksType = {
    confirmationCount: number;
    id: number;
    networkName: string;
    networkURL: string;
    ownerContractAddress: string;
    tokenContractAddress: string;
    treasuryContractAddress: string;
    amountLowerBound: string;
    amountUpperBound: string;
}

export type AccountsType = {
  accountNick: string;
  active: boolean;
  bankAccount: string;
  bankBIC: string;
  bankName: string;
  id: number;
  isDefault: boolean;
  amountLowerBound: string;
  amountUpperBound: string;
}

export type UData = {
        address: string;
        email: string;
        firstName: string;
        loginDate: string;
        userName: string;
        lastName: string;
        middleName: string;
        phone: string;
        userNumber: string;
        userType: string;
        verification: Verification;
        isAgreeReceiveNotification: boolean;
        // PHY
        birthDate?: string;
        inipa?: string;
        passportIssuedBy?: string;
        passportIssuedDate?: string;
        passport?: string;
        tin?: string;
        // LEGAL
        legalAddress?: string;
        legalCOR?: string;
        legalName?: string;
        legalPSRN?: string;
        legalTin?: string;
        legalType?: string;
}

export type MetaMasType = {
  address: string | null;
  balance: number;
  allowance: number;
}

export type DictionariesType = {
  companyAccounts: AccountsType;
  paymentProviders: PaymentServicesType[];
  supportedNetworks: NetworksType[];
}

class Store {
    userData: UData = LocalStorage.get('user') || {} as UData;

    screenWidth: number = window.innerWidth;

    bankAccounts: any[] = [];

    metamask: MetaMasType = LocalStorage.get('meta') || {} as MetaMasType;

    dictionaries: DictionariesType = {} as DictionariesType;

    setDictionaries = (data: DictionariesType) => this.dictionaries = data

    get paymentServices() {
      return this.dictionaries.paymentProviders || [];
    }

    get networks() {
      return this.dictionaries.supportedNetworks || [];
    }

    get companyAccounts() {
      return this.dictionaries.companyAccounts || {} as AccountsType;
    }

    setMetamask = (data: MetaMasType) => {
      this.metamask = data;
      LocalStorage.set('meta', data);
    }

    get defaultAccount() {
      const isHaveDefault = this.bankAccounts.length ? this.bankAccounts.find(el => el.isDefault === true)?.id : undefined;
      const def = isHaveDefault || (this.bankAccounts.length ? this.bankAccounts[0].value : 0);

      return def;
    }

    get isMobile(): boolean {
      return this.screenWidth <= MOBILE_POINT_MENU;
    }

    get isAuth() {
      // add check token r vs bebehs
      return !!Object.keys(this.userData).length;
    }

    get isNewAcc() {
      return false;
    }

    jwtAccess: string = ''

    jwtRefresh: string = ''

    lang = LocalStorage.get('lang') || 'RU'

    payload: any = {}

    setPayload = (data: any) => this.payload = { ...data }

    setPayloadByKey = (key: string, value: any) => {
      this.payload = {
        ...toJS(this.payload),
        [key]: value,
      };
    }

    deletePayloadByKey = (key: string) => {
      const list = toJS(this.payload);
      delete list[key];
      this.payload = list;
    }

    dropPayload = () => this.payload = {}

    language = {
      name: LocalStorage.get('lang') || 'RU',
      langPack: getContent(this.lang),
    }

    setLang = (lang: ILang) => this.lang = lang

    setScreenWidth = (size: number) => {
      this.screenWidth = size;
    }

    setUserData = (data: UData) => {
      const newUserData = this.createUserData(data);
      LocalStorage.set('user', newUserData);
      this.userData = newUserData;
    }

    updateToken = (token: string, type: 'access' | 'refresh') => {
      if (type === 'access') {
        this.jwtAccess = token;
        LocalStorage.set('a', token);
      } else {
        this.jwtRefresh = token;
        LocalStorage.set('r', token);
      }
    }

    setBankAccount = (data: any[]) => {
      this.bankAccounts = data;
    }

    dropBa = () => this.bankAccounts = [];

    createUserData = (data: any) => ({
      address: data.address,
      email: data.email,
      firstName: data.firstName,
      isAgreeReceiveNotification: data.isAgreeReceiveNotification,
      ...(data.userType === 'USER_PHYSICAL' && {
        birthDate: data.individualData.birthDate,
        inipa: data.individualData.inipa,
        passport: `${data.individualData.passportSeries} ${data.individualData.passportNumber}`,
        tin: data.individualData.tin,
        passportIssuedBy: data.individualData.passportIssuedBy,
        passportIssuedDate: data.individualData.passportIssuedDate,
      }),
      ...(data.userType === 'USER_LEGAL' && {
        legalAddress: data.legalData.legalAddress,
        legalCOR: data.legalData.legalCOR,
        legalName: data.legalData.legalName,
        legalPSRN: data.legalData.legalPSRN,
        legalTin: data.legalData.legalTin,
        legalType: data.legalData.legalType,
      }),
      loginDate: data.jwtToken?.loginDate || '',
      userName: data.userName,
      lastName: data.lastName,
      middleName: data.middleName,
      phone: data.phone,
      userNumber: data.userNumber,
      userType: data.userType,
      verification: data.verification,
    })

    updateUserVerification = () => {
      const us = this.userData;
      us.verification = {
        creationDate: `${new Date()}`,
        images: [],
        notes: '',
        updateDate: `${new Date()}`,
        verificationState: 'PENDING',
      };
      this.userData = us;
      LocalStorage.set('user', us);
    }

    setAuth = (data: any) => {
      this.updateToken(data.jwtToken.accessToken, 'access');
      this.updateToken(data.jwtToken.refreshToken, 'refresh');

      const newUserData = this.createUserData(data);
      LocalStorage.set('user', newUserData);
      this.userData = newUserData;
      this.payload = {};
    }

    logout = () => {
      this.bankAccounts = [];
      this.userData = {} as UData;
      this.jwtAccess = '';
      this.jwtRefresh = '';
      LocalStorage.remove('user');
      LocalStorage.remove('r');
      LocalStorage.remove('a');
      LocalStorage.remove('meta');
    }

    updateLang = (lang: ILang) => {
      this.language = {
        name: lang,
        langPack: getContent(lang),
      };
      LocalStorage.set('lang', lang);
    }

    get langPack() {
      return this.language.langPack;
    }
}

decorate(Store, {
  userData: observable,
  isAuth: computed,
  lang: observable,
  langPack: computed,
  setLang: action,
  updateLang: action,
  language: observable,
  screenWidth: observable,
  isMobile: computed,
  isNewAcc: computed,
  payload: observable,
  companyAccounts: computed,
  bankAccounts: observable,
  paymentServices: computed,
  networks: computed,
  defaultAccount: computed,
  metamask: observable,
  dictionaries: observable,
});

const store = new Store();

export default store;
