export const BASE_LINK = !process.env.REACT_APP_BY_LOCALHOST
  ? process.env.REACT_APP_PROD
    ? 'https://portal.liquid.holdings/'
    : 'https://app-backoffice-dev.liquid.holdings/'
  : 'http://localhost:8180/';

export const API_VERSION = 'portal/api/';

export const BINANCE_RPC = 'https://bsc-dataseed.binance.org/';

export const TOKEN_ADDRESS = '0x174Fa651D7b64a21a5BF9d87c3FA801760a87fE3';

export const CONTRACT_ADDRESS = '0x34Cd80d6309b4377d1552298FE715615C3560e30';
export const CONTRACT_ABI = '';

export const infinitySize = 1.157920892373162e+71;

export enum RequestTypes {
    get = 'get',
    post = 'post',
    put = 'put',
    delete = 'delete',
    patch = 'patch',
  }

export enum ApiLinks {
    refresh = '/portal/api/authentication/code/email',
    confirmEmail = 'authentication/code/email',
    signup = 'authorization/sign-up',
    signin = 'authentication/sign-in',
    me = 'authorization/me',
    verification = 'authorization/verification',
    accounts = 'user/accounts',
    networks = 'networks',
    paymentS = 'providers',
    placeOrder = 'orders/place',
    createOrder = 'orders/create',
    getOrderList = 'orders',
    getPublicData = 'company/data',
    password='authorization/password/change',
    passwordPatch='authentication/code/password/change',
    passwordReset='authorization/password/reset',
    passwordPatchReset='authentication/code/password/reset',
    passwordCheckReset='authentication/code/password',
}

export interface IRequestConfig {
    link: '' | ApiLinks;
    method: '' | RequestTypes;
    tag?: string;
  }

export const defaultCFG: IRequestConfig = {
  link: '',
  method: '',
};

export const updateJwtCFG: IRequestConfig = {
  link: ApiLinks.refresh,
  method: RequestTypes.get,
};

export const getChangePasswordCode: IRequestConfig = {
  link: ApiLinks.passwordPatch,
  method: RequestTypes.patch,
};

export const setChangePasswordCode: IRequestConfig = {
  link: ApiLinks.password,
  method: RequestTypes.put,
};

export const getChangePasswordResetCode: IRequestConfig = {
  link: ApiLinks.passwordPatchReset,
  method: RequestTypes.patch,
};

export const checkChangePasswordResetCode: IRequestConfig = {
  link: ApiLinks.passwordCheckReset,
  method: RequestTypes.post,
};

export const setChangePasswordRestCode: IRequestConfig = {
  link: ApiLinks.passwordReset,
  method: RequestTypes.put,
};

export const confirmEmailGet: IRequestConfig = {
  link: ApiLinks.confirmEmail,
  method: RequestTypes.patch,
};

export const confirmEmailSet: IRequestConfig = {
  link: ApiLinks.confirmEmail,
  method: RequestTypes.post,
};

export const signup: IRequestConfig = {
  link: ApiLinks.signup,
  method: RequestTypes.post,
};

export const signin: IRequestConfig = {
  link: ApiLinks.signin,
  method: RequestTypes.post,
};

export const meData: IRequestConfig = {
  link: ApiLinks.me,
  method: RequestTypes.get,
};

export const updateAgree: IRequestConfig = {
  link: ApiLinks.me,
  method: RequestTypes.patch,
};

export const verification: IRequestConfig = {
  link: ApiLinks.verification,
  method: RequestTypes.post,
};

export const accounts: IRequestConfig = {
  link: ApiLinks.accounts,
  method: RequestTypes.get,
};

export const accountsSet: IRequestConfig = {
  link: ApiLinks.accounts,
  method: RequestTypes.post,
};

export const accountsGet: IRequestConfig = {
  link: ApiLinks.accounts,
  method: RequestTypes.get,
};

export const accountsDefault: IRequestConfig = {
  link: ApiLinks.accounts,
  method: RequestTypes.patch,
};

export const accountsDelete: IRequestConfig = {
  link: ApiLinks.accounts,
  method: RequestTypes.delete,
};

export const createOrderCFG: IRequestConfig = {
  link: ApiLinks.createOrder,
  method: RequestTypes.post,
};

export const placeOrderCFG: IRequestConfig = {
  link: ApiLinks.placeOrder,
  method: RequestTypes.post,
};

export const updateOrderCFG: IRequestConfig = {
  link: ApiLinks.placeOrder,
  method: RequestTypes.put,
};

export const getOrders: IRequestConfig = {
  link: ApiLinks.getOrderList,
  method: RequestTypes.get,
};

export const getPublicData: IRequestConfig = {
  link: ApiLinks.getPublicData,
  method: RequestTypes.get,
};
