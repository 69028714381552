import { FC, useEffect, useContext } from 'react';

import { InputProps } from 'antd/lib/input';
import FieldContext from 'rc-field-form/lib/FieldContext';

import { FormItem, FormItemProps } from './FormItem';
import MaskedInput from './MaskedInput';
import { PHONE_MASK, MASK_PLACEHOLDER } from './masks';
import { NOT_EMPTY, NO_RULE, telephoneInputCheck } from './rules';

export type PhoneItemProps = FormItemProps &
    Pick<InputProps, 'disabled' | 'onChange'> & {
        removeChar?: string;
        value?: string;
        uneditable: boolean;
        placeholder?: string;
    }

export const PhoneItem: FC<PhoneItemProps> = ({
  name,
  removeChar = MASK_PLACEHOLDER,
  disabled,
  onChange,
  required,
  placeholder,
  value, label, uneditable,
  ...rest
}) => {
  const { getFieldValue, setFieldsValue } = useContext(FieldContext);

  useEffect(() => {
    const valueIn = getFieldValue(name);

    if (valueIn) setFieldsValue({ [name]: valueIn.replaceAll(removeChar, '') });
  });

  return (
    <div className="inputBox">
      <div className="customInput_label" style={{ fontSize: uneditable ? '14px' : '' }}>{label}</div>
      {uneditable
        ? (
          <span>{value || '---'}</span>)
        : (
          <FormItem
            {...rest}
            name={name}
            required={required}
            rules={[telephoneInputCheck(MASK_PLACEHOLDER), required ? NOT_EMPTY : NO_RULE]}
          >
            <MaskedInput
              placeholder={placeholder || 'Например, +74951234567'}
              mask={PHONE_MASK}
              disabled={disabled}
              bordered={false}
              autoComplete="nope"
            />
          </FormItem>
        )}

    </div>
  );
};
