export const MASK_PLACEHOLDER = '_';
export const MASK_CHAR = '9';
export const PHONE_MASK = `+7 (${MASK_CHAR}${MASK_CHAR}${MASK_CHAR}) ${MASK_CHAR}${MASK_CHAR}${MASK_CHAR}-${MASK_CHAR}${MASK_CHAR}-${MASK_CHAR}${MASK_CHAR}`;
// eslint-disable-next-line max-len
export const ORGANIZATION_LICENSE_MASK = `${MASK_CHAR}${MASK_CHAR}${MASK_CHAR}-${MASK_CHAR}${MASK_CHAR}${MASK_CHAR}${MASK_CHAR}${MASK_CHAR}-${MASK_CHAR}${MASK_CHAR}${MASK_CHAR}${MASK_CHAR}${MASK_CHAR}${MASK_CHAR}`;

export const DATE_MASK = `${MASK_CHAR}${MASK_CHAR}-${MASK_CHAR}${MASK_CHAR}-${MASK_CHAR}${MASK_CHAR}${MASK_CHAR}${MASK_CHAR}`;

export const INIPA_MASK = `${MASK_CHAR}${MASK_CHAR}${MASK_CHAR}-${MASK_CHAR}${MASK_CHAR}${MASK_CHAR}-${MASK_CHAR}${MASK_CHAR}${MASK_CHAR} ${MASK_CHAR}${MASK_CHAR}`;

export const PASS_MASK = `${MASK_CHAR}${MASK_CHAR} ${MASK_CHAR}${MASK_CHAR} ${MASK_CHAR}${MASK_CHAR}${MASK_CHAR}${MASK_CHAR}${MASK_CHAR}${MASK_CHAR}`;

export const getMaskOfLength = (n: number): string => {
  let mask = '';

  for (let i = 0; i < n; i++) {
    mask += MASK_CHAR;
  }

  return mask;
};
