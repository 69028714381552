import { FC, useContext, ChangeEvent } from 'react';

import { Checkbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
// eslint-disable-next-line import/no-extraneous-dependencies
import FieldContext from 'rc-field-form/lib/FieldContext';

import { FormItem, FormItemProps } from './FormItem';

function getValueFromEvent(e: ChangeEvent<HTMLInputElement>) {
  return e.target.checked;
}

const createMarkup = (str:any) => ({ __html: str });

export type CheckboxItemProps = FormItemProps &
    Pick<CheckboxProps, 'disabled' | 'onChange'> & {
        checkboxTitle?: string;
    }

export const CheckboxItem: FC<CheckboxItemProps> = ({
  label,
  name,
  uneditable,
  disabled,
  onChange,
  checkboxTitle,
  ...rest
}) => {
  const context = useContext(FieldContext);

  const renderUneditable = () => <>{checkboxTitle}</>;

  return (
    <FormItem
      {...rest}
      label={label}
      hidden={uneditable && !context.getFieldValue(name)}
      name={name}
      renderUneditable={renderUneditable}
      uneditable={uneditable}
      valuePropName="checked"
      getValueFromEvent={getValueFromEvent}
    >
      <Checkbox disabled={disabled} onChange={onChange}>
        <span dangerouslySetInnerHTML={createMarkup(checkboxTitle)} />
      </Checkbox>
    </FormItem>
  );
};
