import React from 'react';

import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from 'store/mainStore';

const Success = () => {
  const {
    langPack: {
      go_to_activity,
      order_sucсess,
      ok,
    },
  } = store;
  const data = window.location.search.replace('?', '').split('&');
  const payData = data.map((el: any) => ({
    key: el.split('=')[0],
    value: el.split('=')[1],
  }));
  const orderNumber = payData.find((el: any) => el.key === 'MERCHANT_ORDER_ID')?.value;

  return (
    <div className="mt20">
      <Result
        status="success"
        title={`${order_sucсess[0]} ${orderNumber} ${order_sucсess[1]}`}
        extra={(
          <>
            <Link to="/dashboard"><Button type="primary">{ok}</Button></Link>
            <Link to="/dashboard/activity"><Button type="primary">{go_to_activity}</Button></Link>
          </>
)}
      />
    </div>

  );
};

export default observer(Success);
