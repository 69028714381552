import { FC, useContext, useEffect } from 'react';

import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
// eslint-disable-next-line import/no-extraneous-dependencies
import FieldContext from 'rc-field-form/lib/FieldContext';
import { Rule } from 'antd/lib/form';

import { createMinLengthRule, NOT_EMPTY, NUMBER_RULE } from './rules';
import { FormItem, FormItemProps } from './FormItem';
import MaskedInput from './MaskedInput';
import { MASK_PLACEHOLDER } from './masks';

export type NumberItemProps = FormItemProps &
    Pick<InputProps, 'disabled' | 'placeholder' | 'prefix'> & {
        id?: string;
        minLength?: number;
        maxLength?: number;
        mask?: string;
        removeChar?: string | RegExp;
        rules?: Rule[];
        value?: any;
        outRules?: any;
    }

function createPlaceholder(placeholder?: string, minLength?: number, maxLength?: number) {
  if (placeholder) return placeholder;

  if (maxLength && minLength === maxLength) return `${maxLength} цифр`;

  return maxLength ? `до ${maxLength} цифр` : '';
}

export const NumberItem: FC<NumberItemProps> = ({
  minLength,
  maxLength,
  mask,
  removeChar = MASK_PLACEHOLDER,
  required,
  prefix = '',
  placeholder,
  disabled,
  value,
  label,
  uneditable,
  rules = [],
  outRules,
  id,
  ...rest
}) => {
  const { getFieldValue, setFieldsValue } = useContext(FieldContext);

  const resultPlaceholder = createPlaceholder(placeholder, minLength, maxLength);

  useEffect(() => {
    const valueIn = getFieldValue(rest.name);

    if (valueIn) setFieldsValue({ [rest.name]: value.replaceAll(removeChar, '') });
  });

  return (
    <div className="inputBox">
      <div className="customInput_label" style={{ fontSize: uneditable ? '14px' : '' }}>{label}</div>
      {uneditable
        ? (
          <span>{value || '---'}</span>)
        : (
          <FormItem
            {...rest}
            label={undefined}
            required={required}
            rules={outRules ? [...rules, outRules] : [...rules, NOT_EMPTY, NUMBER_RULE, createMinLengthRule(label, minLength)]}
          >
            {mask ? (
              <MaskedInput
                placeholder={resultPlaceholder}
                prefix={prefix}
                mask={mask}
                disabled={disabled}
                bordered={false}
              />
            ) : (
              <Input
                placeholder={resultPlaceholder}
                maxLength={maxLength}
                prefix={prefix}
                disabled={disabled}
                bordered={false}
              />
            )}
          </FormItem>
        )}
      {' '}

    </div>
  );
};
