import { useEffect, useState } from 'react';

import store from 'store/mainStore';
import { observer } from 'mobx-react-lite';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as RuLang } from 'assets/images/ru.svg';
import { ReactComponent as EnLang } from 'assets/images/us.svg';
import { ReactComponent as Avatar } from 'assets/images/avatarDefault.svg';
import AccountModal from 'components/modals/AccountModal';
import {
  accountsGet, getPublicData,
} from 'configs';
import useRequest from 'hooks/useRequest';
import ChangePassword from 'components/modals/ChangePassword';
import { Popover } from 'antd';
import VerificationTag from 'components/VerificationTag';

import './style.scss';

const Header = () => {
  const {
    language, lang, setLang, langPack, isAuth, userData, logout, bankAccounts, setBankAccount, dictionaries, setDictionaries,
  } = store;

  const { change_password, my_account, my_logout } = langPack;
  const {
    fetch: fetchPublicData, requestResult: publicData, isSuccess: isSuccPD,
  } = useRequest(getPublicData);

  const {
    fetch, requestResult, isSuccess, dropState,
  } = useRequest(accountsGet);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenChangePassword, setIsOpenChangePassword] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const toogleModal = () => setIsOpen(!isOpen);
  const toogleModalPassword = () => setIsOpenChangePassword(!isOpenChangePassword);

  const clickOpen = () => {
    toogleModal();
    setVisible(false);
  };

  const clickOpenPassword = () => {
    toogleModalPassword();
    setVisible(false);
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  useEffect(() => {
    if (isAuth && !Object.keys(dictionaries).length) {
      fetchPublicData();
    }
  }, [isAuth, fetchPublicData, dictionaries]);

  useEffect(() => {
    if (isSuccPD && publicData) {
      setDictionaries(publicData);
    }
  }, [isSuccPD, publicData, setDictionaries]);

  useEffect(() => {
    if (!isAuth) dropState();
    if (isAuth && !bankAccounts.length) { isAuth && fetch(); }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, bankAccounts.length]);

  useEffect(() => {
    if (isSuccess && requestResult && !bankAccounts.length && isAuth) {
      setBankAccount(requestResult);
    }
  }, [bankAccounts.length, isSuccess, requestResult, setBankAccount, isAuth]);

  return (
    <div className="container">
      <div className="bgImage" />
      {isAuth && <AccountModal isOpen={isOpen} toogleModal={toogleModal} />}
      {isAuth && <ChangePassword isOpen={isOpenChangePassword} toogleModal={toogleModalPassword} />}
      <div className="header">
        <Logo />
        <div className="header_menu">
          {isAuth && <div style={{ display: 'flex', height: '25px', alignItems: 'center' }}><VerificationTag status={userData.verification?.verificationState || null} /></div>}
          <div className="langIcon" onClick={() => setLang(lang === 'RU' ? 'EN' : 'RU')}>
            {
                            language.name === 'RU'
                              ? <RuLang />
                              : <EnLang />
                          }
          </div>
          { isAuth && (
          <Popover
            visible={visible}
            trigger="click"
            content={(
              <div className="accountMenu">
                <span className="accountMenu_name">{userData.userNumber}</span>
                <span onClick={clickOpen}>{my_account}</span>
                <span onClick={clickOpenPassword}>{change_password}</span>
                <div className="divider" />
                <span onClick={logout}>{my_logout}</span>
              </div>
)}
            onVisibleChange={handleVisibleChange}
          >
            <Avatar onClick={() => setVisible(!visible)} style={{ cursor: 'pointer' }} />
          </Popover>
          )}
        </div>

      </div>

    </div>
  );
};
export default observer(Header);
