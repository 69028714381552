import React from 'react';

import { ReactComponent as Pic } from 'assets/images/identPic.svg';
import store from 'store/mainStore';
import { observer } from 'mobx-react';
import './style.scss';

const HelloIndentification = () => {
  const { langPack } = store;
  const {
    identity_verification_upload_pass_rules,
  } = langPack;
  return (
    <div className="container centred">
      <div className="mt20" />
      <Pic width={300} height={300} />
      <div className="list">
        <h2 className="list_title">{identity_verification_upload_pass_rules.title}</h2>
        <ul>
          {identity_verification_upload_pass_rules.list.map((el: string) => (<li key={el}>{el}</li>))}
        </ul>
      </div>
    </div>
  );
};

export default observer(HelloIndentification);
