import React from 'react';
import './style.scss';

type Props = {
    onChange: Function;
    options: any[];
    selected: string | number;
}

const CustomRadioButton = ({ onChange, options, selected }: Props) => {
  const changeHandler = (data: string | number) => onChange(data);
  return (
    <div className="customRb">
      {
        options.map((el: any) => (
          <div className="customRb_unit" key={el.value} onClick={() => changeHandler(el.value)}>
            <div className={`customRb_but ${selected === el.value ? 'customRb_but--active' : ''}`} />
            <div className="customRb_label">{el.label}</div>
          </div>
        ))
    }
    </div>
  );
};
export default CustomRadioButton;
