import React, { ReactChild } from 'react';
import './style.scss';

type IContentWrapperProps = {
    leftBlock: ReactChild;
    rightBlock?: ReactChild;
}

const ContentWrapper = ({ leftBlock, rightBlock }: IContentWrapperProps) => (
  <div className="contentWraper">
    <div className="contentWraper_left">
      {leftBlock}
    </div>
    <div className="contentWraper_right">
      {rightBlock}
    </div>
  </div>
);
export default ContentWrapper;
