/* eslint-disable max-len */
import { observer } from 'mobx-react-lite';
import store, { UData } from 'store/mainStore';

type Props = {
    legal?: boolean;
    getData?: Function;
    uneditable?: boolean;
    outData: UData;
}

const AccountData = ({ legal = false, uneditable = false, outData }: Props) => {
  const { langPack } = store;

  const data: any = outData || {};

  const {
    user_middle_name,
    user_first_name,
    user_last_name,
    user_email,
    my_account_individual_birthdate,
    my_account_individual_INIPA,
    my_account_individual_passport_issued_by,
    my_account_individual_passport_issued_date,
    my_account_individual_passport_number,
    my_account_individual_passport_series,
    my_account_individual_phone,
    sign_up_individual_password,
    sign_up_individual_password_confirm,
    sign_up_legal_entity_address,
    sign_up_legal_entity_actual_address,
    sign_up_legal_entity_COR,
    sign_up_legal_entity_name,
    sign_up_legal_entity_type,
    sign_up_legal_entity_TIN,
    sign_up_legal_entity_PSRN,
  } = langPack;

  const BASE_FIELDS = [
    {
      type: 'email',
      label: user_email,
      fieldName: 'email',
    },
    {
      type: 'phone',
      label: my_account_individual_phone,
      fieldName: 'phone',
    },
    {
      type: 'password',
      label: sign_up_individual_password,
      fieldName: 'password',
      hideUneditable: true,
    },
    {
      type: 'password',
      label: sign_up_individual_password_confirm,
      hideUneditable: true,
      fieldName: 'confirmPassword',
    },
    {
      label: user_last_name,
      fieldName: 'lastName',
    },
    {
      label: user_first_name,
      fieldName: 'firstName',
    },
    {
      label: user_middle_name,
      fieldName: 'middleName',
    },
  ];

  const LEGAL_LIST = [
    {
      label: sign_up_legal_entity_type,
      fieldName: 'legalType',
    },
    {
      label: sign_up_legal_entity_name,
      fieldName: 'legalName',
    },
    {
      type: 'tin',
      label: sign_up_legal_entity_TIN,
      fieldName: 'legalTin',
    },
    {
      type: 'cor',
      label: sign_up_legal_entity_COR,
      fieldName: 'legalCOR',
    },
    {
      type: 'psrn',
      label: sign_up_legal_entity_PSRN,
      fieldName: 'legalPSRN',
    },
  ];

  const PHYSICAL_LIST = [
    {
      type: 'date',
      label: my_account_individual_birthdate,
      fieldName: 'birthDate',
    },
    {
      type: 'inipa',
      label: my_account_individual_INIPA,
      fieldName: 'inipa',
    },
    {
      type: 'tin',
      label: sign_up_legal_entity_TIN,
      fieldName: 'tin',
    },
    {
      label: my_account_individual_passport_issued_by,
      fieldName: 'passportIssuedBy',
    },
    {
      type: 'date',
      label: my_account_individual_passport_issued_date,
      fieldName: 'passportIssuedDate',
    },
  ];

  return (
    <div className="inpage">
      {
       (uneditable ? BASE_FIELDS.filter(el => !el.hideUneditable) : BASE_FIELDS).map((el: any) => (
         <div className="accountData" key={el.fieldName}>
           <span className="accountData_title">{el.label}</span>
           <span>{data[el.fieldName]}</span>
         </div>
       ))
      }
      {legal
        ? (
          <>
            {
              LEGAL_LIST.map((el: any) => (
                <div className="accountData" key={el.fieldName}>
                  <span className="accountData_title">{el.label}</span>
                  <span>{data[el.fieldName]}</span>
                </div>
              ))
              }
            <div className="accountData">
              <span className="accountData_title">{sign_up_legal_entity_address}</span>
              <span>{data.legalAddress}</span>
            </div>
            <div className="accountData">
              <span className="accountData_title">{sign_up_legal_entity_actual_address}</span>
              <span>{data.address}</span>
            </div>
          </>
        )
        : (
          <>
            {
              PHYSICAL_LIST.map((el: any) => (
                <div className="accountData" key={el.fieldName}>
                  <span className="accountData_title">{el.label}</span>
                  <span>{data[el.fieldName]}</span>
                </div>
              ))
              }
            <div className="accountData">
              <span className="accountData_title">{`${my_account_individual_passport_series} и ${my_account_individual_passport_number.toLowerCase()}`}</span>
              <span>{data.passport}</span>
            </div>
            <div className="accountData">
              <span className="accountData_title">{sign_up_legal_entity_address}</span>
              <span>{data.address}</span>
            </div>
          </>
        )}

    </div>
  );
};
export default observer(AccountData);
