import { useEffect, useState } from 'react';

import store, { MetaMasType } from 'store/mainStore';
import { ReactComponent as Metamask } from 'assets/images/metamask.svg';
import {
  infinitySize,
} from 'configs';
import { RedoOutlined } from '@ant-design/icons';
import { getEllipsisTxt } from 'utils';
import { observer } from 'mobx-react';
import { Form, Modal, Spin } from 'antd';
import ButtonAnt from 'components/ButtonAnt';
import InputItem from 'components/forms/InputItem/InputItem';
import { createWEB3, getConnectData } from 'utils/metamaks_utils';

export const networks: any = {
  BINANCE: {
    chainId: `0x${Number(56).toString(16)}`,
    chainName: 'Smart Chain',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com'],
  },
  ETHEREUM: {
    chainId: `0x${Number(1).toString(16)}`,
    chainName: 'mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://api.mycryptoapi.com/eth'],
    blockExplorerUrls: ['https://etherscan.io'],
  },
};

const Wallet = ({ ntwk }: any) => {
  const {
    langPack: {
      user_btn_cancel,
      confirm,
      connect_to_metamask,
      order_user_wallet_address,
      balance,
      set_unlimited,
      add_allowance,
      allowance,
      allowance_amount,
      how_match,
      request_pending,
      edit_allowance,
      order_network,
    },
    metamask,
    setMetamask,
  } = store;

  const [isLoadingAllowance, setIsLoadingAllowance] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [metaPending, setMetaPending] = useState<boolean>(false);
  const [mLoading, setMLoadingg] = useState<boolean>(false);

  const changeNetwork = async ({ networkName }: any) => {
    try {
      if (!window.ethereum) throw new Error('No crypto wallet found');
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: networks[networkName].chainId,
          },

        ],
      });
    } catch (switchError: any) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{
              ...networks[networkName],
            },
            ],
          });
        } catch (addError) {
          // handle "add" error
        }
      }
    }
  };

  const handleConnectWallet = async () => {
    setMLoadingg(true);
    const connectObj = getConnectData(ntwk);
    const token = createWEB3(connectObj);
    try {
      const userWalletData: MetaMasType = {} as MetaMasType;
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const account = accounts[0];
      userWalletData.address = account;
      const balanceInWallet = await token.methods.balanceOf(account).call();
      const allowanceInContract = await token.methods.allowance(account, ntwk.treasuryContractAddress).call();
      userWalletData.allowance = allowanceInContract / 1000000;
      userWalletData.balance = balanceInWallet / 1000000;
      setMetamask(userWalletData);
      setMetaPending(false);
      setMLoadingg(false);
    } catch (err:any) {
      if (err.code === -32002) {
        setMetaPending(true);
      }
    }
  };

  const handleNetworkSwitch = async (networkName: any) => {
    await changeNetwork({ networkName });
    await handleConnectWallet();
  };

  useEffect(() => {
    if (!ntwk) return;
    handleNetworkSwitch(ntwk.internalName);
    setMLoadingg(false);
    setMetamask({} as MetaMasType);
    handleConnectWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ntwk]);

  const submit = async (values: any) => {
    const { amountAlowances } = values;
    const uint256 = Number(BigInt(amountAlowances * 1000000));
    try {
      setIsLoadingAllowance(true);
      const connectObj = getConnectData(ntwk);
      const token = createWEB3(connectObj);
      await token.methods.approve(ntwk.treasuryContractAddress, uint256).send({ from: metamask.address });
      await handleConnectWallet();
      setIsOpen(false);
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log('This Error', err);
    } finally {
      setIsLoadingAllowance(false);
    }
  };

  const submitUnlimited = async () => {
    // 1.157920892373162e+71
    const uint256 = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
    try {
      setIsLoadingAllowance(true);
      const connectObj = getConnectData(ntwk);
      const token = createWEB3(connectObj);
      await token.methods.approve(ntwk.treasuryContractAddress, uint256).send({ from: metamask.address });
      await handleConnectWallet();
      setIsOpen(false);
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log('This Error', err);
    } finally {
      setIsLoadingAllowance(false);
    }
  };

  return (
    <>
      <Modal
        visible={isOpen}
        title={add_allowance}
        style={{ top: '100px' }}
        zIndex={901}
        destroyOnClose
        footer={[
          <ButtonAnt onClick={() => setIsOpen(!isOpen)}>{user_btn_cancel}</ButtonAnt>,
          <ButtonAnt
            type="primary"
            onClick={submitUnlimited}
            loading={isLoadingAllowance}
            disabled={isLoadingAllowance}
          >
            {set_unlimited}
          </ButtonAnt>,
          <ButtonAnt htmlType="submit" type="primary" form="form" loading={isLoadingAllowance} disabled={isLoadingAllowance}>{confirm}</ButtonAnt>,
        ]}
        onCancel={() => setIsOpen(!isOpen)}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={submit}
          id="form"
          autoComplete="off"
          style={{
            width: '500px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '0px',
          }}
        >
          <div className="signinForm_box">
            <InputItem
              label={allowance_amount}
              name="amountAlowances"
              type="number"
              initialValue={metamask.allowance === infinitySize ? null : metamask.allowance}
              placeholder={metamask.allowance === infinitySize ? set_unlimited : how_match}
              maxLength={250}
              required
            />
          </div>
        </Form>
      </Modal>
      {metamask.address
        ? (
          <div className="metamask_body">
            <span className="metamask_meta">
              <Metamask />
              <span className="metamask_refresh" onClick={handleConnectWallet}>
                <RedoOutlined />
              </span>
            </span>
            <div className="metamask_metadata">
              <div className="metamask_line">
                <span>
                  {order_network}
                  :
                </span>
                <span>
                  {ntwk?.networkName || ''}
                </span>
              </div>
              <div className="metamask_line">
                <span>
                  {order_user_wallet_address}
                  :
                </span>
                <span>
                  {getEllipsisTxt(metamask.address)}
                </span>
              </div>
              <div className="metamask_line">
                <span>
                  {balance}
                  :
                </span>
                <span>
                  {`${metamask.balance} RUBL`}
                </span>
              </div>
              <div className="metamask_line">
                <span>
                  {allowance}
                  :
                </span>
                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '5px' }}>
                  {metamask.allowance === infinitySize ? set_unlimited : `${metamask.allowance || 0} RUBL`}
                </span>
              </div>
              <div style={{ display: 'flex', marginTop: '10px', color: 'white', justifyContent: 'flex-end' }}>
                <ButtonAnt onClick={() => setIsOpen(true)} type="primary" color="white">{edit_allowance}</ButtonAnt>
              </div>
            </div>
          </div>
        )
        : (
          <div>
            <div className="metamask_button">
              <span>
                {mLoading ? <Spin /> : connect_to_metamask}
              </span>
              <Metamask />
            </div>
            <span className="note">Выберите сеть</span>
            {metaPending && <span className="note">{request_pending}</span>}
          </div>
        )}
    </>
  );
};

export default observer(Wallet);
