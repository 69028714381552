/* eslint-disable no-console */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import CustomRadioButton from 'components/customCheckbox/CustomRadioButton';
import AccountModal from 'components/modals/AccountModal';
import Checkbox from 'components/InputChechbox';
import store, { NetworksType } from 'store/mainStore';
import OrderModal from 'components/modals/RedeemOrderModal';
import { observer } from 'mobx-react-lite';
import ContentWrapper from 'components/Columns/Columns';
import InputItem from 'components/forms/InputItem/InputItem';
import { Form } from 'antd';
import { SelectItem } from 'components/forms/InputItem/SelectItem';
import ButtonAnt from 'components/ButtonAnt';
import {
  createOrderCFG, infinitySize,
} from 'configs';
import useRequest from 'hooks/useRequest';
import checkFields from 'utils/checkFields';
import { checkMetaSum, checkMinMax } from 'components/forms/InputItem/rules';
import { toJS } from 'mobx';

import Wallet from './Wallet';

const isMetamask = window!.ethereum;

const Redeem = () => {
  const {
    isMobile, langPack: {
      order_amount,
      order_user_loan_agreement,
      order_my_bank_accounts,
      order_check_fees,
      order_update_bank_accounts,
      here,
      order_btn_create_order,
      order_network,
      user_btn_cancel,
      choose_network,
      need_to_install,
      need_to_add_ba,
      minAmount,
      maxAmount,
    },
    bankAccounts,
    defaultAccount,
    networks,
    metamask,
    companyAccounts,
  } = store;
  const { fetch, requestResult, isLoading, dropState } = useRequest(createOrderCFG);
  const [form] = Form.useForm();

  const [acc, setAcc] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toogleModal = () => setIsOpen(!isOpen);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [agreeLoanAgreement, setAgreeLoanAgreement] = useState<boolean>(false);
  const [selectedNTWK, setSelectedNTWK] = useState<any>(null);
  const [isOpenOrder, setIsOpenOrder] = useState<boolean>(false);
  const [bam, setBam] = useState<boolean>(false);
  const toogleModalOrder = () => setIsOpenOrder(!isOpenOrder);

  const optionsNetworks = networks.map((el: any) => ({ label: el.networkName, value: el.id, ...el }));

  const accounts = bankAccounts.length ? bankAccounts.map((el: any) => ({ label: el.accountNick, value: el.id, ...el })) : [];

  const sendData = (value: any) => {
    setIsOpenOrder(true);
    const { amount, networkId } = value;
    const body = {
      orderType: 'REDEEM',
      address: metamask.address,
      agreeLoanAgreement,
      amount: Number(amount),
      networkId: networks.find((el: NetworksType) => el.id === networkId)?.id || 0,
      paymentMethodEnum: 'WIRE_TRANSFER',
      userAccountId: acc,
    };
    fetch(body);
  };

  const checkAmount = (data: any) => {
    if (!data.target.value) return;
    if (Number(data.target.value) > metamask.allowance && Number(data.target.value) < metamask.balance) {
      setBam(true);
    } else {
      setBam(false);
    }
  };

  const setNetworkData = (a: number) => {
    setSelectedNTWK(toJS(networks).find((el: any) => el.id === a) || null);
  };

  useEffect(() => {
    if (acc === 0 && defaultAccount) setAcc(defaultAccount);
  }, [defaultAccount, acc]);

  const validator = () => {
    const obj = form.getFieldsValue();

    const isGood = checkFields(obj) && Number(obj.amount) <= metamask.allowance && Number(obj.amount) < metamask.balance;

    setDisableButton(!isGood);
  };

  const clearForm = () => {
    form.resetFields();
    dropState();
    setAcc(0);
    validator();
    setAgreeLoanAgreement(false);
  };

  useEffect(() => {
    acc !== 0 && setAcc(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccounts]);

  return (
    <div className="inpage">
      <AccountModal isOpen={isOpen} toogleModal={toogleModal} />
      <OrderModal isOpen={isOpenOrder} ntwk={selectedNTWK} redeem orderData={requestResult || {}} toogleModal={toogleModalOrder} isMobile={isMobile} clearForm={clearForm} />
      <ContentWrapper
        leftBlock={(
          <Form
            name="basic"
            // initialValues={{ network: firstNetwork }}
            onFinish={sendData}
            id="reedForm"
            onFieldsChange={validator}
            form={form}
            autoComplete="off"
          >
            <InputItem
              label={(
                <>
                  <div>
                    <span>{`${order_amount} ${minAmount}: ${companyAccounts?.amountLowerBound} ${maxAmount}: ${companyAccounts?.amountUpperBound}`}</span>
                    { metamask.address && metamask.allowance !== infinitySize
                      ? (
                        <span style={{ color: bam ? 'red' : '', marginLeft: '10px' }}>
                          Доступно в кошельке:
                          {metamask.allowance || 0}
                          {' '}
                          RUBL
                        </span>
                      ) : null}

                  </div>
                </>
              )}
              name="amount"
              type="number"
              onChange={checkAmount}
              required={false}
              rules={[checkMinMax(Number(companyAccounts?.amountLowerBound || 0), Number(companyAccounts?.amountUpperBound || 0)), checkMetaSum(metamask?.balance || 0)]}
              placeholder={order_amount}
              uneditable={false}
              maxLength={250}
            />
            <SelectItem
              label={order_network}
              options={optionsNetworks}
              onChange={setNetworkData}
              name="networkId"
              placeholder={choose_network}
            />
            <div className="inpage_box label mt20">
              {order_my_bank_accounts}
            </div>
            <div className="mt20" />
            {accounts.length ? (
              <CustomRadioButton
                options={accounts}
                onChange={(d: number) => {
                  setAcc(d);
                  validator();
                }}
                selected={acc}
              />
            ) : <div style={{ color: 'red' }}>{need_to_add_ba}</div>}
            <div className="mt20">
              {order_update_bank_accounts}
              {' '}
              <span onClick={toogleModal} className="textBut">{here}</span>
            </div>
            <div className="mt20">
              {order_check_fees}
              {' '}
              <a href="https://liquid.holdings/fees" target="_blank" className="textBut" rel="noreferrer">{here}</a>
            </div>
          </Form>
        )}
        rightBlock={(
          <>
            {isMetamask ? (<Wallet ntwk={selectedNTWK} />)
              : (
                <div className="note">
                  {need_to_install}
                  {' '}
                  <br />
                  <a href="https://metamask.io/" target="_blank" rel="noreferrer" style={{ marginLeft: '10px' }}>METAMASK</a>
                </div>
              )}
            <div>
              <Checkbox
                label={order_user_loan_agreement}
                checkBoxHandler={() => {
                  setAgreeLoanAgreement(!agreeLoanAgreement);
                  validator();
                }}
                checked={agreeLoanAgreement}
              />
              <div className="rbuttonSec_but">
                <ButtonAnt
                  htmlType="submit"
                  type="primary"
                  form="reedForm"
                  large
                  loading={isLoading}
                  disabled={disableButton || !agreeLoanAgreement || isOpenOrder || !accounts.length || acc === 0}
                >
                  {order_btn_create_order}

                </ButtonAnt>
                <ButtonAnt
                  onClick={clearForm}
                  large
                >
                  {user_btn_cancel}

                </ButtonAnt>
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
};

export default observer(Redeem);
