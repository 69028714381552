import { FC } from 'react';

import { Select, SelectProps } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies

import { FormItem, FormItemProps } from './FormItem';

const { Option } = Select;

export type SelectedProps = FormItemProps &
    Pick<SelectProps, 'disabled' | 'onChange'> & {
      options: any[];
      placeholder: string;
    }

export const SelectItem: FC<SelectedProps> = ({
  label,
  name,
  uneditable,
  disabled,
  onChange,
  placeholder,
  options = [],
  ...rest
}) => (
  <div className="inputBox">
    <div className="customInput_label" style={{ fontSize: uneditable ? '14px' : '' }}>{label}</div>
    <FormItem
      {...rest}
    // label={label}
      name={name}
      uneditable={uneditable}
    >
      <Select disabled={disabled} onChange={onChange} style={{ backgroundColor: '#F7F9FB' }} bordered={false} placeholder={placeholder}>
        {options.map((el: any) => (
          <Option value={el.value} key={el.value}>{el.label}</Option>
        ))}
      </Select>
    </FormItem>
  </div>
);
