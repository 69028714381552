/* eslint-disable max-len */
import { useEffect } from 'react';

import store from 'store/mainStore';
import { Checkbox, Form, Modal } from 'antd';
import ButtonAnt from 'components/ButtonAnt';
import useRequest from 'hooks/useRequest';
import { accountsSet } from 'configs';
import InputItem from 'components/forms/InputItem/InputItem';
import { NumberItem } from 'components/forms/InputItem/NumberItem';
import { getMaskOfLength, MASK_PLACEHOLDER } from 'components/forms/InputItem/masks';

import './style.scss';

type Props = {
    isOpen: boolean;
    toogleModal: any;
}

const BankAccoutModal = ({ isOpen, toogleModal }: Props) => {
  const {
    langPack: {
      my_account_individual_bank_account_nickname,
      user_bank_name,
      user_bank_bic,
      user_bank_account_number,
      confirm,
      user_btn_cancel,
      add_account,
      default_account,
    },
  } = store;

  const {
    fetch, requestResult, isSuccess, isLoading,
  } = useRequest(accountsSet);

  const submit = (values: any) => {
    fetch(values);
  };

  useEffect(() => {
    if (isSuccess && requestResult) {
      toogleModal();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, requestResult]);

  return (
    <Modal
      visible={isOpen}
      title={add_account}
      style={{ top: '100px' }}
      zIndex={901}
      destroyOnClose
      footer={[
        <ButtonAnt onClick={toogleModal}>{user_btn_cancel}</ButtonAnt>,
        <ButtonAnt htmlType="submit" type="primary" form="form" loading={isLoading}>{confirm}</ButtonAnt>]}
      onCancel={toogleModal}
    >
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={submit}
        id="form"
        autoComplete="off"
        style={{ width: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '0px' }}
      >
        <div className="signinForm_box">
          <InputItem
            label={my_account_individual_bank_account_nickname}
            name="accountNick"
            placeholder={my_account_individual_bank_account_nickname}
            maxLength={250}
            required
          />
          <NumberItem
            label={user_bank_account_number}
            name="bankAccount"
            mask={getMaskOfLength(20)}
            maxLength={20}
            minLength={20}
            removeChar={MASK_PLACEHOLDER}
            placeholder={user_bank_account_number}
            required
          />
          <NumberItem
            label={user_bank_bic}
            name="bankBIC"
            mask={getMaskOfLength(9)}
            maxLength={9}
            minLength={9}
            removeChar={MASK_PLACEHOLDER}
            placeholder={user_bank_bic}
            required
          />
          <InputItem
            label={user_bank_name}
            name="bankName"
            placeholder={user_bank_name}
            maxLength={250}
            required
          />
          <Form.Item
            name="isDefault"
            valuePropName="checked"
          >
            <Checkbox>
              {default_account}
            </Checkbox>
          </Form.Item>
        </div>

      </Form>
    </Modal>

  );
};

export default BankAccoutModal;
