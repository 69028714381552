/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import BankAccounts from 'components/AccountFields/BankAccounts';
import store from 'store/mainStore';
// \import AccountFieldsMain from 'components/AccountFields/AccountFieldsMain';
import AccountData from 'components/AccountFields/AccountData';
import { Checkbox, Modal } from 'antd';
import ButtonAnt from 'components/ButtonAnt';
import useRequest from 'hooks/useRequest';
import { updateAgree, verification } from 'configs';
import { observer } from 'mobx-react';
import VerificationTag from 'components/VerificationTag';

import VerificationModal from './VerificationModal';

import './style.scss';

type Props = {
    isOpen: boolean;
    toogleModal: any;
}

const AccountModal = ({ isOpen, toogleModal }: Props) => {
  const {
    langPack: {
      user_consent_receive_notifications,
      user_my_account,
      need_verification,
      user_btn_save,
      start_verification,
    },
    userData,
    setUserData,
    updateUserVerification,
  } = store;

  const [openVerif, setOpenVerif] = useState<boolean>(false);
  const [agree, setAgreeLoanAgreement] = useState<boolean>(userData.isAgreeReceiveNotification);
  const toogleModalVer = () => setOpenVerif(!openVerif);

  const {
    fetch, requestResult, isSuccess,
  } = useRequest(updateAgree);

  const {
    fetch: verify, isSuccess: successVerify,
  } = useRequest(verification);

  const verLegal = () => verify({});

  useEffect(() => {
    if (successVerify) {
      updateUserVerification();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successVerify]);

  const Verification = () => (
    <div className="accountModal_title">
      <div className="accountModal_name">
        <span className="accountModal_nameModal">
          {user_my_account}
          :
        </span>
        <span className="accountModal_nameAcc">
          {userData.userNumber}
        </span>
      </div>
      <div style={{ display: 'flex', marginLeft: '20px' }}>
        <VerificationTag status={userData.verification?.verificationState || null} />

      </div>
      {!userData.verification && (
      <div className="accountModal_startVerification">
        <div onClick={() => (userData.userType === 'USER_LEGAL' ? verLegal() : toogleModalVer())} style={{ color: '#2DAFE7', fontWeight: 600, cursor: 'pointer' }}>
          {start_verification}
        </div>
      </div>
      )}
    </div>
  );

  useEffect(() => {
    (requestResult && isSuccess) && setUserData(requestResult);
  }, [requestResult, isSuccess, setUserData]);

  return (
    <>
      <VerificationModal isOpen={openVerif} toogleModal={toogleModalVer} />
      <Modal
        visible={isOpen}
        destroyOnClose
        title={<Verification />}
        footer={false}
        style={{ top: '10px', minHeight: '90vh', maxWidth: '1128px' }}
        width="90%"
        zIndex={900}
        onCancel={toogleModal}
        bodyStyle={{ minHeight: '90vh', width: '90vw', maxWidth: '1128px' }}
      >
        <div>
          <div className="mt20" />
          <AccountData getData={() => {}} outData={userData} uneditable legal={userData.userType !== 'USER_PHYSICAL'} />
          <div className="mt20" />
          <div style={{ display: 'flex', minHeight: '50px', alignItems: 'center' }}>
            <Checkbox checked={agree} onChange={(e: any) => setAgreeLoanAgreement(e.target.checked)}>{user_consent_receive_notifications}</Checkbox>
            {userData.isAgreeReceiveNotification !== agree && <ButtonAnt type="primary" onClick={() => fetch({})}>{user_btn_save}</ButtonAnt>}
          </div>
          <div className="divider mt20" />
          <div className="mt20" />
          {userData?.verification?.verificationState !== 'VERIFIED' ? <span className="w80 centred">{need_verification}</span> : <BankAccounts />}
        </div>
      </Modal>
    </>

  );
};

export default observer(AccountModal);
