import { useEffect, useState } from 'react';

import { Collapse, Popconfirm, Spin } from 'antd';
import store from 'store/mainStore';
import { DeleteOutlined, FileDoneOutlined } from '@ant-design/icons';
import ButtonAnt from 'components/ButtonAnt';
import { useMount } from 'hooks';
import useRequest from 'hooks/useRequest';
import { accountsDefault, accountsDelete, accountsGet } from 'configs';
import BankAccoutModal from 'components/modals/BankAccoutModal';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

const { Panel } = Collapse;

const BankAccounts = () => {
  const [openAccounts, setOpenAccounts] = useState<boolean>(false);
  const {
    fetch, requestResult, isSuccess, isLoading,
  } = useRequest(accountsGet);

  const {
    fetch: fetchPatch, isSuccess: isSuccessPatch, dropState,
  } = useRequest(accountsDefault);

  const {
    fetch: fetchDelete, isSuccess: isSuccessDelete, dropState: dropDelete, metaData,
  } = useRequest(accountsDelete);

  const toogleModalAcc = () => {
    openAccounts && fetch();
    setOpenAccounts(!openAccounts);
  };

  const {
    langPack: {
      user_bank_accounts,
      user_bank_account_add,
      user_bank_account_remove_que,
      user_bank_account_default_que,
      user_bank_name,
      user_bank_bic,
      user_bank_account_number,
      user_btn_confirm,
      user_btn_cancel,
    },
    setBankAccount,
    bankAccounts,
  } = store;

  const onChange = () => {
    // console.log(key);
  };

  const setDefault = (event: any, id: number) => {
    event.stopPropagation();
    dropState();
    fetchPatch({}, {}, `/${id}`);
  };

  const deleteAccount = (event: any, id: number) => {
    event.stopPropagation();
    dropDelete();
    fetchDelete({}, {}, `/${id}`);
  };

  const genExtra = (acc: any) => (
    <>
      {!acc?.isDefault
      && (
      <Popconfirm
        title={user_bank_account_default_que}
        onConfirm={(event: any) => setDefault(event, acc.id)}
        onCancel={(event: any) => event.stopPropagation()}
        okText={user_btn_confirm}
        cancelText={user_btn_cancel}
      >
        <FileDoneOutlined
          onClick={event => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
          }}
        />
      </Popconfirm>
      )}
      <Popconfirm
        title={user_bank_account_remove_que}
        onConfirm={(event: any) => deleteAccount(event, acc.id)}
        onCancel={(event: any) => event.stopPropagation()}
        okText={user_btn_confirm}
        cancelText={user_btn_cancel}
      >
        <DeleteOutlined
          onClick={event => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
          }}
        />
      </Popconfirm>
    </>
  );
  useMount(() => {
    fetch();
  });

  useEffect(() => {
    if (isSuccessPatch) {
      fetch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessPatch]);

  useEffect(() => {
    if (isSuccessDelete) {
      const id = Number(metaData.addTolink.replace('/', ''));
      const filtred = toJS(bankAccounts).filter((el: any) => el.id !== id);
      setBankAccount(filtred);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDelete]);

  useEffect(() => {
    if (isSuccess && requestResult) {
      setBankAccount(requestResult);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, requestResult]);

  if (isLoading) return <><Spin /></>;

  return (
    <>
      <BankAccoutModal isOpen={openAccounts} toogleModal={toogleModalAcc} />

      <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
        <h2>{user_bank_accounts}</h2>
      </div>

      <div className="mt20" />
      <Collapse
        defaultActiveKey={['1']}
        onChange={onChange}
        expandIconPosition="start"
      >
        {(bankAccounts || []).map((el: any) => (
          <Panel header={`${el.accountNick} ${el.isDefault ? '(По умолчанию)' : ''}`} key={el.accountNick} extra={genExtra(el)} showArrow={false}>
            <div className="accBox_line">
              <span>
                {user_bank_name}
              </span>
              <span>
                {el.bankName}
              </span>
            </div>
            <div className="accBox_line">
              <span>
                {user_bank_bic}
              </span>
              <span>
                {el.bankBIC}
              </span>
            </div>
            <div className="accBox_line">
              <span>
                {user_bank_account_number}
              </span>
              <span>
                {el.bankAccount}
              </span>
            </div>
          </Panel>
        ))}
      </Collapse>
      {bankAccounts.length < 3 && (
      <div className="mt20" style={{ width: '320px' }}>
        <ButtonAnt onClick={toogleModalAcc}>{`+ ${user_bank_account_add}`}</ButtonAnt>
      </div>
      )}

    </>
  );
};
export default observer(BankAccounts);
