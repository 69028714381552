import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import ABI_CONTRACT from 'configs/contract.json';
import ABI_CONTRACT_ETH from 'configs/eth_contract.json';
import ABI_TREASURY_CONTRACT from 'configs/treasury.json';
import ABI_TREASURY_CONTRACT_ETH from 'configs/eth_treasury.json';

export const infinitySize = 1.157920892373162e+71;

export type GetAbiType = 'BINANCE' | 'BINANCE_TREASURY' | 'ETHEREUM' | 'ETHEREUM_TREASURY'

const getAbi = (name: GetAbiType, isTreasury: boolean = false) => {
  const seach_by = `${name}${isTreasury ? '_TREASURY' : ''}`;
  switch (seach_by) {
    case 'BINANCE':
      return ABI_CONTRACT;
    case 'BINANCE_TREASURY':
      return ABI_TREASURY_CONTRACT;
    case 'ETHEREUM':
      return ABI_CONTRACT_ETH;
    case 'ETHEREUM_TREASURY':
      return ABI_TREASURY_CONTRACT_ETH;
    default:
      return null;
  }
};

export const getConnectData = (network: any) => {
  const { networkURL, internalName, tokenContractAddress, ownerContractAddress, treasuryContractAddress } = network;

  return {
    networkURL,
    internalName,
    tokenContractAddress,
    ownerContractAddress,
    treasuryContractAddress,
  };
};

export const createWEB3 = (network: any, isTreasury: boolean = false) => {
  const { networkURL, internalName, tokenContractAddress, treasuryContractAddress } = network;

  const abi = getAbi(internalName, isTreasury);

  const web3 = new Web3(networkURL);
  web3.eth.setProvider(Web3.givenProvider);
  return new web3.eth.Contract(abi as AbiItem[], isTreasury ? treasuryContractAddress : tokenContractAddress);
};
